<script>
import {
  SearchIcon,
  // InfoIcon,
  // MoreVerticalIcon
} from "@zhuowenli/vue-feather-icons";

import { ref, watch, inject } from "vue";

import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";
import 'dayjs/locale/id'

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
// import SocketMixin from '../../mixins/SocketMixins';
// import DebounceMixin from '../../mixins/DebounceMixins';
import axios from "axios";
import Swal from "sweetalert2";

import DropZone from "@/components/widgets/dropZone";
// import { file } from '@babel/types';

import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/dist/style.css";

import FsLightbox from 'fslightbox-vue/v3';

// dayjs.locale('id')
// dayjs().locale('de').format()
dayjs.extend(relativeTime);
dayjs.extend(calendar);

export default {
  // mixins: [SocketMixin],
  setup() {
    let files = ref([]);
    let dropzoneFile = ref("");
    let imagesPreview = ref("");
    let debounceTimeout;
    let listUser = ref([]);
    const syncContact = inject("sync_contact")

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    const drop = async (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      files.value[0] = dropzoneFile.value;
      imagesPreview.value = await toBase64(files.value[files.value.length - 1]);
    };
    const selectedFile = async () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      files.value[0] = dropzoneFile.value;
      imagesPreview.value = await toBase64(files.value[files.value.length - 1]);
    };
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (err) => reject(err);
      });
    const checkStringOfLink = (text) => {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(text);
    };
    watch(
      () => [...files.value],
      (currentValue) => {
        return currentValue;
      }
    );

    return {
      dropzoneFile,
      files,
      drop,
      selectedFile,
      imagesPreview,
      Toast,
      v$: useVuelidate(),
      checkStringOfLink,
      debounceTimeout,
      listUser,
      syncContact
    };
  },
  page: {
    title: "Chat",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      toggler: false,
      sourcesImages: "",
      whatsappStatus: false,
      chatData: [],
      chatMessagesData: [],
      agentData: [],
      searchAgentInput: "",
      title: "Chat",
      valueSearch: "",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Chat",
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: "",
      },
      username: "Steven Franklin",
      profile: require("@/assets/images/users/user-dummy-img.jpg"),
      userNotFound: require("@/assets/images/error500.png"),
      phoneNumber: 0,
      userid: 0,
      user: null,
      userStatus: null,
      imageModal: "",
      captionModal: "",
      showFormUpload: false,
      currentImages: undefined,
      previewImages: undefined,
      messagesReply: false,
      isMediaReply: false,
      isSticker: false,
      nameReply: "",
      pesanReply: "",
      pesanId: "",
      tagvalue: null,
      tagoption: [],
      lastEdit: null,
      create: null,
      agentHandle: [],
      socket: null,
      newMessage: false,
      note: null,
      tagValue: [],
      agentChatName: null,
      agentChatRole: null,
      searchChatInput: "",
      shimmerOn: false,
      fetchChatRoom: true,
      // for loadmore
      finished: false,
      page: 1,
      pageSize: 25,
      itemsInPages: 10,
      pages: [],
      styleObject: [],
      downloadImage: [],
      openModal: false,
      resultSearchMessage: [],
      db: inject("db")
    };
  },
  components: {
    Layout,
    SearchIcon,
    DropZone,
    Multiselect,
    EmojiPicker,
    FsLightbox
    // InfoIcon,
    // MoreVerticalIcon
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    selectEmoji(emoji) {
      this.form.message += emoji.i;
    },
    async getUserDetil() {
      this.agentHandle = [];
      var secure =
        "ClientID=" +
        process.env.VUE_APP_CLIENT_ID +
        "&AccessToken=" +
        localStorage.getItem("jwt");
      var param = "?ID=" + this.userid + "&" + secure;
      const userDetil = await axios.get(
        process.env.VUE_APP_URL_API + "customer-api/getCustomerByID" + param
      );

      this.note = userDetil.data.Data.Note;

      this.create = userDetil.data.Data.Created;
      this.lastEdit = userDetil.data.Data.LastEdited;

      if (userDetil.data.Data.AgentAssigned !== null) {
        userDetil.data.Data.AgentAssigned.forEach((agents) => {
          this.agentHandle.push({
            ID: agents.ID,
            Name: agents.Name,
            avatar: agents.Avatar
              ? agents.Avatar.Original
              : require("@/assets/images/users/avatar-2.jpg"),
          });
        });
      }

      this.tagoption = [];
      this.tagValue = [];
      const tags = await axios.get(
        process.env.VUE_APP_URL_API + "customer-api/getAllTags?" + secure
      );
      tags.data.Data.forEach((items) => {
        this.tagoption.push({
          value: items.ID,
          label: items.Title,
        });
      });

      if (userDetil.data.Data.Tags != null) {
        userDetil.data.Data.Tags.forEach((t) => {
          this.tagValue.push(t.ID);
        });
      }
    },
    replyMessages(pesan, nama, chatId, isMedia, data) {
      this.nameReply = nama;
      this.pesanReply = pesan;
      this.pesanId = chatId;
      this.messagesReply = true;
      this.isMediaReply = isMedia;
      this.isSticker = data.type === "sticker" ? true : false;
      this.previewImages = data.media;
      document.getElementById("chatInput").focus();
    },
    closeReply() {
      this.messagesReply = false;
      this.nameReply = null;
      this.pesanReply = null;
      this.pesanId = null;
      this.isMediaReply = false;
      this.previewImages = null;
    },
    setDataModal(data) {
      if (data.type === 'image') {
        if (("downloadImage" in data) && !data.downloadImage) {
          this.openModal = true;
          this.imageModal = data.media;
          this.captionModal = data.message;

          this.sourcesImages = data.media
          this.toggler = this.toggler = !this.toggler;

          let anchorPreviewImage = document.querySelector(".popup-img");
          anchorPreviewImage.setAttribute("data-bs-toggle", "modal")
          anchorPreviewImage.setAttribute("data-bs-target", "#zoomInModal")
          // anchorPreviewImage.click();
        } else {
          this.openModal = false;
          this.Toast.fire({
            title: 'please download media for open!!',
            icon: 'info'
          })
        }
      }
    },
    scrollToBottom(id) {
      var self = this;
      setTimeout(function () {
        var simpleBar = document
          .getElementById(id)
          .querySelector("#chat-conversation .simplebar-content-wrapper")
          ? document
            .getElementById(id)
            .querySelector("#chat-conversation .simplebar-content-wrapper")
          : "";

        var offsetHeight = document.getElementsByClassName(
          "chat-conversation-list"
        )[0]
          ? document
            .getElementById(id)
            .getElementsByClassName("chat-conversation-list")[0]
            .scrollHeight -
          window.innerHeight +
          600
          : 0;

        if (offsetHeight)
          simpleBar.scrollTo({
            top: offsetHeight,
            behavior: "smooth",
          });

        self.onLoad(simpleBar);
      }, 50);
    },
    onLoad(el) {
      var self = this;
      el.addEventListener("scroll", async function () {
        var top = el.scrollTop;
        if (top === 0 && self.whatsappStatus) {
          var indexEl = self.chatMessagesData.length - 1;
          var posTop = document.getElementById("buble-" + indexEl);

          // var contact = JSON.parse(localStorage.getItem("Contact")).data.Data
          var contact = await self.getAllContact();
          var usersContact = contact.find((el) => parseInt(el.Phone) === parseInt(self.phoneNumber))
          self.userStatus = usersContact.Status;

          const convertation = await axios.post(
            process.env.VUE_APP_WA_BASE_URL +
            "api/whatsapp/conversation?clientid=abcd1234",
            {
              phone: self.phoneNumber,
              limit: 25,
              fetched: self.chatMessagesData.length,
              kodemb: JSON.parse(localStorage.getItem("user")).Company
                .CompanyCode,
              download_media: false,
              // created_date: parseInt(self.chatData[idx].firstTimeChat),
              created_date: parseInt(usersContact.CreatedTime),
            }
          );

          let convertationData = convertation.data.data.reverse();

          let tempArray = [];
          convertationData.forEach((item, index) => {
            const regexItalic = /(_)([^_]+?)(_)/gs;
            const regexBold = /(\*)([^*]+?)(\*)/gs;
            const regexStrike = /(~)([^~]+?)(~)/gs;

            var body = item.body;
            body = body.replace(regexItalic, `<i>$2</i>`);
            body = body.replace(regexBold, `<b>$2</b>`);
            body = body.replace(regexStrike, `<s>$2</s>`);
            body = body.replace(/\n/g, "<br/>");

            tempArray.push({
              align: item.fromMe === true ? "right" : "",
              name: self.username,
              chatID: item.id.id,
              message: item.type === "revoked" ? "<i>pesan di hapus</i>" : body,
              time: item.timestamp,
              hasMedia: "deprecatedMms3Url" in item._data ? true : false,
              ack: item.ack ?? 0,
              fromMe: item.fromMe,
              phone: self.phoneNumber,
              type: item.type,
            });

            if ("deprecatedMms3Url" in item._data) {
              if ("downloadedMedia" in item) {
                tempArray[index]["media"] = item.downloadedMedia
                  ? "data:" +
                  item.downloadedMedia.mimetype +
                  ";base64," +
                  item.downloadedMedia.data
                  : "data:" +
                  item.downloadedMedia.mimetype +
                  ";base64," +
                  item._data.body;
                // this.styleObject[index] = {'filter':'blur(3px)'}
                // this.downloadImage[index] = false;
                tempArray[index]['style'] = { 'filter': 'blur(0px)' }
                tempArray[index]['downloadImage'] = false
              } else {
                tempArray[index]['style'] = { 'filter': 'blur(3px)' }
                tempArray[index]['downloadImage'] = true
                // this.downloadImage[index] = true;
                var mimeType = "";
                // this.styleObject[index] = {'filter':'blur(0px)'}
                if ("mimetype" in item._data) {
                  mimeType = item._data.mimetype;
                } else {
                  mimeType = "image/png";
                }
                tempArray[index]["media"] =
                  "data:" + mimeType + ";base64," + item._data.body;
              }
              tempArray[index]["message"] = item._data.caption
                ? item._data.caption
                : "";
              tempArray[index]["type"] =
                item.type === "sticker" ? "sticker" : "image";
            }

            if (item.hasQuotedMsg && "quotedMsg" in item._data) {
              if ("deprecatedMms3Url" in item._data.quotedMsg) {
                item._data.quotedMsg.body =
                  "data:" +
                  item._data.quotedMsg.mimetype +
                  ";base64," +
                  item._data.quotedMsg.body;
              }
              tempArray[index]["quotedMsg"] = item._data.quotedMsg;
              tempArray[index]["quotedParticipant"] =
                item._data.quotedParticipant;
              tempArray[index]["quotedParticipant"]["name"] =
                parseInt(item._data.quotedParticipant.user) === self.phoneNumber
                  ? self.username
                  : "Anda";
            }
          });
          tempArray.reverse();
          let fixList = tempArray.concat(self.chatMessagesData);
          self.chatMessagesData = fixList;

          el.scrollTop = posTop.offsetHeight - (posTop.offsetHeight / 2 + 5);
        }
      });
    },
    async chatUsername(name, image, telp, id) {
      window.event.preventDefault();
      if(!this.whatsappStatus){
        this.Toast.fire({
          title: "Whatsapp Not Ready",
          icon: "error"
        })
        return;
      }

      let element = this.$el.querySelector(`#row-${id}`)
      element.classList.add("active");

      this.username = name;
      this.profile = image;
      this.phoneNumber = telp;
      this.userid = id;
      this.usermessage = "Hello";
      this.chatMessagesData = [];
      this.resultSearchMessage = [];
      this.searchChatInput = "";
      this.closeFormUpload();

      if (localStorage.getItem("notif") !== null) {
        const notif = JSON.parse(localStorage.getItem("notif"));
        var idxLocalStore = notif.findIndex(
          (el) => parseInt(el.from.split("@")[0]) === parseInt(telp)
        );
        if (idxLocalStore !== -1) {
          notif.splice(idxLocalStore, 1);
          localStorage.setItem("notif", JSON.stringify(notif));
        }
      }

      // var contact = JSON.parse(localStorage.getItem("Contact")).data.Data
      var contact = await this.getAllContact();

      var usersContact = contact.find((el) => parseInt(el.Phone) === parseInt(telp))
      this.userStatus = usersContact.Status;

      var kodemb = JSON.parse(localStorage.getItem("user")).Company.CompanyCode;
      const convertation = await axios.post(
        process.env.VUE_APP_WA_BASE_URL +
        "api/whatsapp/conversation?clientid=abcd1234",
        {
          phone: telp,
          limit: 25,
          fetched: this.chatMessagesData.length,
          kodemb: kodemb,
          download_media: false,
          created_date: parseInt(usersContact.CreatedTime),
        }
      );

      if (this.whatsappStatus) {
        const unreadcount = await axios.post(
          process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/mark-as-read",
          {
            phone: telp,
            kodemb: kodemb,
          },
          {
            headers: {
              clientid: "abcd1234",
            },
          }
        );

        if (unreadcount.status == 200) {
          var index = this.chatData.findIndex((el) => el.telp === telp);
          this.chatData[index].unread_count = 0;
          this.cachingChatData();
        }
      }

      let convertationData = convertation.data.data;

      let chatDataDB = null;
      if (convertationData.length != 0) {
        var secure =
          "?ClientID=" +
          process.env.VUE_APP_CLIENT_ID +
          "&AccessToken=" +
          localStorage.getItem("jwt");
        var bodyParam = new FormData();
        bodyParam.append("CustomerID", this.userid);
        chatDataDB = await axios.post(
          process.env.VUE_APP_URL_API + "agent-api/getChat" + secure,
          bodyParam
        );
      }

      this.styleObject = [];
      this.downloadImage = [];
      convertationData.forEach(async (item, index) => {
        const regexItalic = /(_)([^_]+?)(_)/gs;
        const regexBold = /(\*)([^*]+?)(\*)/gs;
        const regexStrike = /(~)([^~]+?)(~)/gs;

        var body = item.body;
        body = body.replace(regexItalic, `<i>$2</i>`);
        body = body.replace(regexBold, `<b>$2</b>`);
        body = body.replace(regexStrike, `<strike>$2</strike>`);
        body = body.replace(/\n/g, "<br/>");

        if (item.links.length) {
          const isValidLink = this.checkStringOfLink(body);
          if (isValidLink) {
            var bodyLink =
              "<a href='" + body + "' target='_blank'>" + body + "</a>";
            body = bodyLink;
          }
        }

        this.chatMessagesData.push({
          align: item.fromMe === true ? "right" : "",
          name: this.username,
          chatID: item.id.id,
          message: item.type === "revoked" ? "<i>pesan di hapus</i>" : body,
          time: item.timestamp,
          hasMedia: "deprecatedMms3Url" in item._data,
          ack: item.ack,
          fromMe: item.fromMe,
          phone: this.phoneNumber,
          type: item.type,
        });

        if ("deprecatedMms3Url" in item._data) {
          if ("downloadedMedia" in item) {
            this.chatMessagesData[index]["media"] = item.downloadedMedia
              ? "data:" +
              item.downloadedMedia.mimetype +
              ";base64," +
              item.downloadedMedia.data
              : "data:" +
              item.downloadedMedia.mimetype +
              ";base64," +
              item._data.body;
            /* this.styleObject[index] = { filter: "blur(0px)" };
            this.downloadImage[index] = false; */
            this.chatMessagesData[index]['style'] = { filter: "blur(0px)" }
            this.chatMessagesData[index]['downloadImage'] = false
          } else {
            if (item.type === "image" || item.type === "chat") {
              /* this.styleObject[index] = { filter: "blur(3px)" };
              this.downloadImage[index] = true; */
              this.chatMessagesData[index]['style'] = { filter: "blur(3px)" }
              this.chatMessagesData[index]['downloadImage'] = true
              var mimeType = "";
              if ("mimetype" in item._data) {
                mimeType = item._data.mimetype;
              } else {
                mimeType = "image/png";
              }
              item._data.type = "image";
              this.chatMessagesData[index]["media"] =
                "data:" + mimeType + ";base64," + item._data.body;
            }
          }
          switch (item._data.type) {
            case "sticker":
              this.chatMessagesData[index]["type"] = "sticker";
              break;

            case "image":
              this.chatMessagesData[index]["type"] = "image";
              var caption_body = item._data.caption;
              caption_body = caption_body?.replace(regexItalic, `<i>$2</i>`);
              caption_body = caption_body?.replace(regexBold, `<b>$2</b>`);
              caption_body = caption_body?.replace(
                regexStrike,
                `<strike>$2</strike>`
              );
              this.chatMessagesData[index]["message"] = item._data.caption
                ? caption_body
                : "";
              break;

            case "video":
              this.chatMessagesData[index]["type"] = "video";
              this.chatMessagesData[index]["message"] =
                "<i>Unsupported Message Content</i>";
              break;

            default:
              console.log("default break switch");
              break;
          }
        }

        if (item.hasQuotedMsg && "quotedMsg" in item._data) {
          if (
            "deprecatedMms3Url" in item._data.quotedMsg &&
            item._data.quotedMsg.type !== "sticker"
          ) {
            item._data.quotedMsg.body =
              "data:" +
              item._data.quotedMsg.mimetype +
              ";base64," +
              item._data.quotedMsg.body;
          }
          if (
            "deprecatedMms3Url" in item._data.quotedMsg &&
            item._data.quotedMsg.type === "sticker"
          ) {
            console.log("quoted msg sticker");
          }
          this.chatMessagesData[index]["quotedMsg"] = item._data.quotedMsg;
          this.chatMessagesData[index]["quotedParticipant"] =
            item._data.quotedParticipant;
          this.chatMessagesData[index]["quotedParticipant"]["name"] =
            parseInt(item._data.quotedParticipant.user) === this.phoneNumber
              ? this.username
              : "Anda";
        }
      });

      this.chatMessagesData.forEach((item, index) => {
        if (chatDataDB.data.Data.length > 0) {
          chatDataDB.data.Data.forEach((element) => {
            if (item.chatID === element.MessageID) {
              this.chatMessagesData[index]["agent"] = element.Agent;
            }

            if (element.MessageID == "0" && element.Status == 0) {
              let checkChatID = this.chatMessagesData.findIndex(el => {
                return el.chatID === element.ID
              });

              if (checkChatID === -1) {
                let hasMedia = element.Type == 'image' ? true : false;
                let type = hasMedia ? 'image' : 'chat';

                let pushConvertation = {
                  align: "right",
                  name: this.username,
                  chatID: element.ID,
                  message: element.Content,
                  time: element.Time,
                  hasMedia: hasMedia,
                  ack: 4,
                  fromMe: true,
                  phone: this.phoneNumber,
                  type: type,
                  agent: element.Agent
                }

                if (hasMedia) {
                  pushConvertation['style'] = {
                    filter: 'blur(3px)'
                  }
                  pushConvertation['downloadImage'] = true;
                  pushConvertation['media'] = element.Photo.Original;
                }

                this.chatMessagesData.push(pushConvertation);
              }
            }
          });
        }
      })

      if (convertationData.length > 0) {
        this.chatMessagesData.sort(function (a, b) { return a.time - b.time });
        var currentChatId = "users-chat";
        this.scrollToBottom(currentChatId);

        setTimeout(() => {
          if (this.userStatus === "resolved") {
            document
              .getElementById("chatInput")
              .setAttribute("disabled", "disabled");
            document
              .getElementById("chatInput")
              .setAttribute("placeholder", "Customer Is Resolved");
            document
              .getElementById("btnSendMessage")
              .setAttribute("disabled", "disabled");
          } else {
            document.getElementById("chatInput").removeAttribute("disabled");
            document.getElementById("btnSendMessage").removeAttribute("disabled");
          }
        }, 1000);
      }

      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        let convvalue = this.chatMessagesData.length > 1 ? 1 : 0;
        setTimeout(() => {
          this.$el.querySelector("#conv-"+convvalue).classList.add("user-chat-show")
        }, 500);
      }
    },
    async resendMessage(messageData) {
      let formData = null;
      const agent = JSON.parse(localStorage.getItem("userdata"));
      var agentParams = {
        ID: agent.ID,
        Name: agent.Name,
        Role: agent.Role,
        Avatar: agent.Avatar,
      };

      if(messageData.type == 'image'){
        formData = new FormData();
        formData.append("phone", this.phoneNumber);
        formData.append("type", "MEDIA");
        formData.append(
          "kodemb",
          JSON.parse(localStorage.getItem("userdata")).Company.CompanyCode
        );
        formData.append("media_source", "URL");
        formData.append("message", messageData.message ?? "");
        formData.append("caption", messageData.message ?? "");
        formData.append("image_url", messageData.media);
        formData.append("agent", JSON.stringify(agentParams));
      } else {
        formData = {
          kodemb: JSON.parse(localStorage.getItem("userdata")).Company
            .CompanyCode,
          phone: this.phoneNumber,
          message: messageData.message,
          agent: JSON.stringify(agentParams),
        };
      }


      const messRess = await axios.post(
        process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/send",
        formData,
        {
          headers: {
            "content-type": "application/json; charset=utf-8",
            clientid: "abcd1234",
            authorization: JSON.parse(localStorage.getItem("userdata"))
              .Company.CompanyCode,
          },
        }
      )

      if (messRess.data.status == 200) {
        var dataPost = new FormData();
        dataPost.append("id", messageData.chatID);
        dataPost.append("message_id", messRess.data.data.id.id);

        if (messRess.data.status === 200) {
          var param =
            "?ClientID=" +
            process.env.VUE_APP_CLIENT_ID +
            "&AccessToken=" +
            localStorage.getItem("jwt");
          await axios.post(
            process.env.VUE_APP_URL_API + "agent-api/updateChat" + param,
            dataPost
          );

          this.pushToTop(this.phoneNumber, messRess.data.data);

          let lastIndex = this.chatMessagesData.findIndex(el => {
            return parseInt(el.chatID) === parseInt(messageData.chatID)
          });
          if (lastIndex !== -1) {
            this.chatMessagesData.splice(lastIndex, 1);
          }
        } else {
          this.Toast.fire({
            icon: "error",
            title: messRess.data.message
          });
        }
      } else {
        this.Toast.fire({
          title: 'Fails resend message',
          icon: 'warning'
        })
      }
    },
    async formSubmit() {
      this.submitted = true;
      var btnSend = document.getElementById("btnSendMessage");
      btnSend.setAttribute("disabled", "disabled");

      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid && !this.showFormUpload) {
        return;
      } else {
        const message = this.form.message ?? "";
        const phone = this.phoneNumber;
        let formData = null;

        const agent = JSON.parse(localStorage.getItem("userdata"));

        var agentParams = {
          ID: agent.ID,
          Name: agent.Name,
          Role: agent.Role,
          Avatar: agent.Avatar,
        };

        if (!this.showFormUpload && !this.messagesReply) {
          formData = {
            kodemb: JSON.parse(localStorage.getItem("userdata")).Company
              .CompanyCode,
            phone: phone,
            message: message,
            agent: JSON.stringify(agentParams),
          };
        } else if (this.messagesReply) {
          formData = {
            message_id: this.pesanId,
            fetched: 25,
            phone: phone,
            message: message,
            kodemb: JSON.parse(localStorage.getItem("userdata")).Company
              .CompanyCode,
            agent: JSON.stringify(agentParams),
          };
          this.messagesReply = false;
        } else {
          formData = new FormData();
          formData.append("phone", phone);
          formData.append("type", "MEDIA");
          formData.append(
            "kodemb",
            JSON.parse(localStorage.getItem("userdata")).Company.CompanyCode
          );
          formData.append("media_source", "LOCAL");
          formData.append("message", message);
          formData.append("image_local", this.files[this.files.length - 1]);
          formData.append("agent", JSON.stringify(agentParams));
        }

        try {
          var dataPost = new FormData();
          dataPost.append("AgentID", agent.ID);
          dataPost.append("MessageID", "0");
          dataPost.append("CustomerID", this.userid);
          dataPost.append("Content", message);
          if (this.files.length > 0) {
            dataPost.append("Photo", this.files[this.files.length - 1]);
          }
          await axios.post(process.env.VUE_APP_URL_API + "agent-api/saveChat", dataPost, {
            params: {
              ClientID: process.env.VUE_APP_CLIENT_ID,
              AccessToken: localStorage.getItem("jwt")
            }
          }).then(async res => {
            let data = res.data;
            let sendWaFails = false;
            let ressWa = null;
            let hasMedia = data.Data.Type == 'image' ? true : false;
            let type = hasMedia ? 'image' : 'chat';

            if (data.Meta.Code === 200) {
              const messRess = await axios.post(
                process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/send",
                formData,
                {
                  headers: {
                    "content-type": "application/json; charset=utf-8",
                    clientid: "abcd1234",
                    authorization: JSON.parse(localStorage.getItem("userdata"))
                      .Company.CompanyCode,
                  },
                }
              )

              // for testing whatsapp not send
              /* let messRess = {
                status: 500
              } */

              let pushConvertation = {
                align: "right",
                name: this.username,
                chatID: data.Data.ID,
                message: data.Data.Content,
                time: data.Data.Time,
                hasMedia: hasMedia,
                ack: 0,
                fromMe: true,
                phone: this.phoneNumber,
                type: type,
                agent: data.Data.Agent
              }

              if (hasMedia) {
                pushConvertation['style'] = {
                  filter: 'blur(0px)'
                }
                pushConvertation['downloadImage'] = false;
                pushConvertation['media'] = data.Data.Photo.Original;
              }

              if (messRess.status == 200) {
                var dataPost = new FormData();
                dataPost.append("id", data.Data.ID);
                dataPost.append("message_id", messRess.data.data.id.id);

                if (messRess.data.status === 200) {
                  var param =
                    "?ClientID=" +
                    process.env.VUE_APP_CLIENT_ID +
                    "&AccessToken=" +
                    localStorage.getItem("jwt");
                  await axios.post(
                    process.env.VUE_APP_URL_API + "agent-api/updateChat" + param,
                    dataPost
                  );

                  ressWa = messRess.data.data;
                } else {
                  /* this.Toast.fire({
                    icon: "error",
                    title: messRess.data.message
                  }); */
                  sendWaFails = true;
                  pushConvertation.ack = 4;
                  this.chatMessagesData.push(pushConvertation);
                }
              } else {
                sendWaFails = true;
                pushConvertation.ack = 4;
                this.chatMessagesData.push(pushConvertation);
              }

              if (sendWaFails) {
                ressWa = {
                  body: data.Data.Content,
                  hasMedia: hasMedia,
                  timestamp: data.Data.Time,
                  fromMe: true,
                  unread_count: 0,
                  ack: 4,
                  type: type
                }
              }
              var currentChatId = "users-chat";
              this.scrollToBottom(currentChatId);
              this.pushToTop(this.phoneNumber, ressWa);
              this.cachingChatData();
            } else {
              this.Toast.fire({
                title: 'Internal Server Error',
                text: data.Meta.Message,
                icon: 'error'
              })
            }
          }).catch(err => {
            this.Toast.fire({
              title: 'Internal Server Error',
              text: err.message,
              icon: 'error'
            })
          })
        } catch (error) {
          let errorData = error.response.data
          this.Toast.fire({
            icon: "error",
            title: errorData.message
          });
        }
      }

      this.submitted = false;
      this.form = {};
      btnSend.removeAttribute("disabled");

      if (this.showFormUpload) {
        this.closeFormUpload();
      }
    },
    /* 
     * function indexDB
     */
    async getAllContact(){
      return new Promise((resolve, reject) => {
        let trans = this.db.transaction(['customer'], 'readonly');
        trans.oncomplete = () => {
          console.log("Success get customer idb");
          resolve(customer);
        }

        trans.onerror = e => {
          console.log("error get customer idb");
          reject(e)
        }

        let store = trans.objectStore("customer");
        let objStoreReq = store.getAll();
        let customer = [];

        objStoreReq.onsuccess = () => {
          objStoreReq.result.sort((a, b) => dayjs(b.TimestampLastChat).unix() - dayjs(a.TimestampLastChat).unix());
          customer = objStoreReq.result
        }

        objStoreReq.onerror = () => {
          console.log("Error get customer");
        }
      })
    },
    async addCustomerToDB(cust){
      return new Promise((resolve, reject) => {
        let trans = this.db.transaction(['customer'], 'readwrite')
        let store = trans.objectStore('customer')
        
        for (let _ = 0; _ < cust.length; _++) {
          const item = cust[_];
          item['id'] = item.ID
          store.add(item);
        }

        /* cust.map(item => {
          item.id = item?.ID ?? 0
          store.add(item);
        }) */

        trans.oncomplete = () => {
          console.log("Success add customer idb");
          return resolve()
        }

        trans.onerror = e => {
          console.log("Error add customer idb");
          return reject(e);
        }
      })
    },
    async cleanDataDB()
    {
      return new Promise((resolve, reject) => {
        let trans = this.db.transaction(['customer'], 'readwrite')
        let objectStore = trans.objectStore("customer");

        let clearRequest = objectStore.clear();
        clearRequest.onsuccess = () => {
          console.log("Success clean idb");
          resolve();
        }

        clearRequest.onerror = () => {
          console.log("Error clean idb");
          reject();
        }
      })
    },
    /* 
     * end
     */
    async getUser(params, start = 0, to = this.itemsInPages, isLoadMore = false) {
      await this.checkWhatsappStatus();
      if (!isLoadMore) {
        this.chatData = [];
        this.pages = { data: { Data: [] } };
      }

      this.shimmerOn = true;
      this.fetchChatRoom = true;
      this.chatMessagesData = [];
      this.username = "";
      let request = null

      let cst = await this.getAllContact();
      let localContact = JSON.stringify({
        data: {
          Data: cst
        }
      })
      
      if (localContact) {
        localContact = JSON.parse(localContact)
        if (params.toLowerCase() == 'all') {
          if (localContact.data.Data.length != 0) {
            if (localContact.data.Data.length >= this.itemsInPages) {
              let newArr  = [];

              if(!isLoadMore){
                newArr = localContact.data.Data.slice(start, to)
              } else {
                request = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/getCustomerData`, {
                  params: {
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    AccessToken: localStorage.getItem("jwt"),
                    Start: start,
                    Count: 10,
                    Kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
                  }
                });

                if(request.data.Meta.Code == 200){
                  newArr = request.data.Data;
                }
              }

              if (this.pages.data.Data.length === 0) {
                this.pages = {
                  data: {
                    Data: newArr
                  }
                };
              } else {
                newArr.forEach(element => {
                  this.pages.data.Data.push(element)
                });
              }
            } else {
              this.pages = localContact;
            }
          } else {
            request = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/getCustomerData`, {
              params: {
                ClientID: process.env.VUE_APP_CLIENT_ID,
                AccessToken: localStorage.getItem("jwt"),
                Start: start,
                Count: 10,
                Kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
              }
            });

            let newArr = [];
            if(request.data.Meta.Code == 200){
              newArr = request.data.Data;
            }

            if(this.pages.data.Data.length){
              newArr.forEach(item => {
                this.pages.data.Data.push(item);
              })
            } else {
              this.pages = {
                data: {
                  Data: newArr
                }
              };
            }
          }
        } else {
          request = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/getCustomerData`, {
            params: {
              ClientID: process.env.VUE_APP_CLIENT_ID,
              AccessToken: localStorage.getItem("jwt"),
              Start: start,
              Count: 10,
              Kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
              status: params.toLowerCase()
            }
          });

          let contactStatus = [];
          if(request.data.Meta.Code == 200){
            contactStatus = request.data.Data;
          }

          if(contactStatus.length){
            if(this.pages.data.Data.length){
              contactStatus.forEach(item => {
                let checkReady = this.pages.data.Data.find(el => {
                  return el.ID === item.ID
                })
                if(!checkReady){
                  this.pages.data.Data.push(item)
                }
              })
            } else {
              this.pages.data.Data = contactStatus;
            }
          }
        }
      } else {
        this.pages = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/getCustomerData`, {
          params: {
            ClientID: process.env.VUE_APP_CLIENT_ID,
            AccessToken: localStorage.getItem("jwt"),
            Start: start,
            Count: 10,
            Kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
          }
        });
      }

      if (this.pages.data.Data !== null || this.pages.data.Data.length > 0) {
        let sortContact = this.pages.data.Data.sort((a, b) => this.toTimeStamp(b.TimestampLastChat) - this.toTimeStamp(a.TimestampLastChat))
        this.pages.data.Data = sortContact
        if(isLoadMore){
          let dataContact = JSON.stringify(sortContact);
          let dataSave = []
          JSON.parse(dataContact).slice(start, to).forEach(item => {
            dataSave.push(item)
          });
          await this.addCustomerToDB(dataSave);
        }
      } else {
        this.pages.data.Data = [];
      }

      // let tempArrUserData = [];
      var waGateway = null;
      var resWa = null;

      if (this.pages.data.Data.length !== 0 && this.whatsappStatus) {
        this.shimmerOn = false;

        // set untuk menampilkan data contact terlebih dahulu
        // for (let _idx = 0; _idx < userChat.data.Data.length; _idx++) {
        for (let _idx = start; _idx < this.pages.data.Data.length; _idx++) {
          const contactItem = this.pages.data.Data[_idx];
          let Tag = [];

          if (contactItem.Tags !== null) {
            contactItem.Tags.forEach(element => {
              Tag.push(element.Title);
            });
          }

          // prioritas ambil dari cache
          let cacheChatData = null;
          if (localStorage.getItem("ChatData")) {
            cacheChatData = JSON.parse(localStorage.getItem("ChatData")).find(el => {
              return parseInt(el.id) === parseInt(contactItem.ID)
            })

            this.chatData.push({
              id: contactItem.ID,
              image: contactItem.Avatar ? contactItem.Avatar.Original : this.profile,
              name: contactItem.Name,
              telp: parseInt(contactItem.Phone),
              message: cacheChatData ? cacheChatData.message : "<i>not supported</i>",
              hasMedia: cacheChatData ? cacheChatData.hasMedia : "",
              ack: cacheChatData ? cacheChatData.ack : 0,
              Tags: Tag.join(","),
              timestamp: cacheChatData ? cacheChatData.timestamp : 0,
              unread_count: cacheChatData ? cacheChatData.unread_count : 0,
              fromMe: cacheChatData ? cacheChatData.fromMe : false,
              status: contactItem.Status,
              firstTimeChat: contactItem.CreatedTime,
              shimmer: cacheChatData ? cacheChatData.shimmer : true,
              type: cacheChatData ? cacheChatData.type : "chat"
            })
          }

          let chatDataReady = this.chatData.find(el => el.id === contactItem.ID);
          if (!chatDataReady) {
            this.chatData.push({
              id: contactItem.ID,
              image: contactItem.Avatar === null
                ? this.profile : contactItem.Avatar.Original,
              name: contactItem.Name,
              telp: parseInt(contactItem.Phone),
              message: cacheChatData ? cacheChatData.message : "<i>not supported</i>",
              hasMedia: cacheChatData ? cacheChatData.hasMedia : "",
              ack: cacheChatData ? cacheChatData.ack : 0,
              Tags: Tag.join(","),
              timestamp: cacheChatData ? cacheChatData.timestamp : 0,
              unread_count: cacheChatData ? cacheChatData.unread_count : 0,
              fromMe: cacheChatData ? cacheChatData.fromMe : false,
              status: contactItem.Status,
              firstTimeChat: contactItem.CreatedTime,
              shimmer: cacheChatData ? cacheChatData.shimmer : true,
              type: cacheChatData ? cacheChatData.type : "chat"
            });
          }
          this.sortChatRooms();
        }

        var stringTags = "";
        // set untuk mengambil latest message dan time last message
        for (let index = start; index < this.chatData.length; index++) {
          waGateway = await axios.post(
            process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/chat-rooms",
            {
              phones: [this.pages.data.Data[index].Phone],
              kodemb: JSON.parse(localStorage.getItem("user")).Company
                .CompanyCode,
            },
            {
              headers: {
                ClientID: "abcd1234",
              },
            }
          );

          if (waGateway.data.status != 500) {
            resWa = waGateway.data.data[0];

            var idxChatData = this.chatData.findIndex(row => {
              return parseInt(row.telp) === parseInt(resWa.id.user);
            })

            if (resWa.last_message) {

              resWa.hasMedia = "deprecatedMms3Url" in resWa.last_message._data;
              if (resWa.hasMedia) {
                if (resWa.last_message._data.type !== "image") {
                  if(resWa.last_message.type !== 'sticker'){
                    resWa.last_message.type = "image";
                  }
                  resWa.last_message.hasMedia = true;
                }
              }

              let contact = this.pages.data.Data[index];
              let chatDataDB = null;
              let lastMsgDB = null;

              if(contact){
                var bodyParam = new FormData();
                bodyParam.append("CustomerID", contact.ID);
                chatDataDB = await axios.post(
                  process.env.VUE_APP_URL_API + "agent-api/getChat",
                  bodyParam,
                  {
                    params: {
                      AccessToken: localStorage.getItem("jwt"),
                      ClientID: process.env.VUE_APP_CLIENT_ID,
                    }
                  }
                );
                if(chatDataDB.data.Meta.Code === 200){
                  lastMsgDB = chatDataDB.data.Data.reverse()[0];
                  if(lastMsgDB){
                    if(lastMsgDB.Time > resWa.last_message.timestamp){
                      if(lastMsgDB.Type === 'image'){
                        resWa.hasMedia = true;
                        resWa.last_message.type = "image";
                        resWa.last_message._data.type = "image";
                        resWa.last_message.hasMedia = true;
                        resWa.last_message._data.hasMedia = true;
                      } else {
                        resWa.hasMedia = false;
                        resWa.last_message.type = "chat";
                        resWa.last_message._data.type = "chat";
                        resWa.last_message.hasMedia = false;
                        resWa.last_message._data.hasMedia = false;
                        resWa.last_message._data.deprecatedMms3Url = null;
                      }
                      if(lastMsgDB.Type === 'chat'){
                        resWa.last_message.body = lastMsgDB.Content
                        resWa.last_message._data.body = lastMsgDB.Content
                      }
                      resWa.last_message.timestamp = lastMsgDB.Time;
                      resWa.last_message.ack = 4;
                      resWa.last_message._data.ack = 4;
                    }
                  }
                }
              }

              const regexItalic = /(_)([^_]+?)(_)/gs;
              const regexBold = /(\*)([^*]+?)(\*)/gs;
              const regexStrike = /(~)([^~]+?)(~)/gs;

              var body = resWa.last_message.body ?? this.chatData[index]['message'];
              body = body ?? "<i>tidak ada pesan</i>";

              body = body.replace(regexItalic, `<i>$2</i>`);
              body = body.replace(regexBold, `<b>$2</b>`);
              body = body.replace(regexStrike, `<strike>$2</strike>`);
              
              if(this.chatData[idxChatData]){
                if (parseInt(this.chatData[idxChatData]['ack']) === 4) {
                  body = this.chatData[idxChatData]['message'];
                  resWa.last_message.ack = this.chatData[idxChatData]['ack'];
                  resWa.last_message.type = this.chatData[idxChatData]['type'];
                }

                var isRevoked = resWa.last_message._data.type === "revoked" ?? true;
                this.chatData[idxChatData]["message"] = isRevoked ? "<i>pesan ini dihapus</i>" : body;
                this.chatData[idxChatData]['hasMedia'] = resWa.last_message.hasMedia;
                this.chatData[idxChatData]['ack'] = resWa.last_message.ack;
                this.chatData[idxChatData]['timestamp'] = resWa.last_message.timestamp;
                this.chatData[idxChatData]['unread_count'] = resWa.unread_count;
                this.chatData[idxChatData]['fromMe'] = resWa.last_message.fromMe;
                this.chatData[idxChatData]['type'] = resWa.last_message.type;
                if (resWa.photo !== null) {
                  this.chatData[idxChatData]['image'] = resWa.photo;
                }
                this.chatData[idxChatData]['shimmer'] = false;
                this.chatData[idxChatData]['Tags'] = stringTags;
              }
            } else {
              if (idxChatData !== -1) {
                this.chatData[idxChatData]["message"] = "<i>belum ada pesan</i>";
                this.chatData[idxChatData]['hasMedia'] = false;
                this.chatData[idxChatData]['ack'] = 0;
                this.chatData[idxChatData]['timestamp'] = new Date(Date.parse(this.pages.data.Data[index].TimestampLastChat)).getTime() / 1000;
                this.chatData[idxChatData]['unread_count'] = resWa.unread_count;
                this.chatData[idxChatData]['fromMe'] = false;
                this.chatData[idxChatData]['type'] = "chat";
                if (resWa.photo !== null) {
                  this.chatData[idxChatData]['image'] = resWa.photo;
                }
                this.chatData[idxChatData]['shimmer'] = false;
                this.chatData[idxChatData]['Tags'] = stringTags;
              }
            }
            this.sortChatRooms();
            // For chaching chat data
          }
        }
        this.cachingChatData();
      } else if (!this.whatsappStatus) {
        this.shimmerOn = false;

        let Tag = [];
        for (let _i = start; _i < this.pages.data.Data.length; _i++) {
          const item = this.pages.data.Data[_i];
          if (item.Tags !== null) {
            item.Tags.forEach(element => {
              Tag.push(element.Title);
            });
          }
  
          this.chatData.push({
            id: item.ID,
            image: item.Avatar ? item.Avatar.Original : this.profile,
            name: item.Name,
            telp: parseInt(item.Phone),
            message: "<i>whatsapp not ready</i>",
            hasMedia: false,
            ack: 0,
            Tags: Tag.join(","),
            timestamp: item.CreatedTime,
            unread_count: 0,
            fromMe: false,
            status: item.Status,
            firstTimeChat: item.CreatedTime,
            shimmer: false,
            type: "chat"
          })
        }
      }
      this.fetchChatRoom = false;
    },
    cachingChatData() {
      const key = "ChatData";
      let isChatCahche = localStorage.getItem(key);
      let dataLocal = this.chatData;
      if (isChatCahche) {
        dataLocal = JSON.parse(isChatCahche);
        let pushData = [];
        this.chatData.forEach(item => {
          let checkData = dataLocal.find(el => {
            return el.id == item.id
          })
          if(!checkData){
            pushData.push(item);
          }
        })

        pushData.forEach(elPush => {
          dataLocal.push(elPush);
        })
        // localStorage.removeItem(key);
      }
      localStorage.setItem(key, JSON.stringify(dataLocal));
    },
    toTimeStamp(date) {
      return new Date(Date.parse(date)).getTime() / 1000
    },
    async pushToTop(phoneNumbers, response, self = null) {
      if (self === null) {
        self = this;
      }

      var index = self.chatData.findIndex(
        (element) => parseInt(element.telp) === phoneNumbers
      );

      if (index !== -1) {
        if (!response.hasMedia) {
          self.chatData[index].message = response.body;
        } else {
          self.chatData[index].hasMedia = response.hasMedia;
          self.chatData[index].message = "<i class='ri-image-line'></i> Photo";
        }
        self.chatData[index].timestamp = response.timestamp;
        if (!response.fromMe) {
          if (parseInt(self.phoneNumber) === parseInt(phoneNumbers)) {
            self.chatData[index].unread_count = 0;
          } else {
            self.chatData[index].unread_count =
              self.chatData[index].unread_count + 1;
          }
        } else {
          self.chatData[index].unread_count = 0;
        }
        self.chatData[index].fromMe = response.fromMe;
        self.chatData[index].ack = response.ack;
      } else {
        var url =
          process.env.VUE_APP_URL_API +
          "customer-api/getCustomerByPhone?Phone=" +
          phoneNumbers;
        let userChat = await axios.get(
          url +
          "&ClientID=" +
          process.env.VUE_APP_CLIENT_ID +
          "&AccessToken=" +
          localStorage.getItem("jwt")
        );

        if (userChat.data.Meta.Code === 200) {
          var resWa = response;
          var isRevoked = resWa._data.type === "revoked" ?? true;

          const chatRoomWa = await axios.post(
            process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/chat-rooms",
            {
              phones: [phoneNumbers],
              limit: 1,
              kodemb: JSON.parse(localStorage.getItem("user")).Company
                .CompanyCode,
            },
            {
              headers: {
                ClientID: "abcd1234",
              },
            }
          );

          let readyInArray = self.chatData.findIndex(item => parseInt(item.telp) === phoneNumbers) === -1;

          if (readyInArray) {
            let ava = chatRoomWa.data.data[0].photo ?? userChat.data.Data.Avatar.Original

            self.chatData.splice(0, 0, {
              id: userChat.data.Data.ID,
              image: ava,
              name: userChat.data.Data.Name,
              telp: parseInt(userChat.data.Data.Phone),
              message: isRevoked ? "<i>pesan ini dihapus</i>" : resWa.body,
              hasMedia: resWa.hasMedia,
              ack: resWa.ack,
              timestamp: resWa.timestamp,
              unread_count: chatRoomWa.data.data[0].unread_count,
              fromMe: resWa.fromMe,
              status: userChat.data.Data.Status,
              type: resWa.type,
              Tags: null,
            });
          }

        }
      }
      self.sortChatRooms();
    },
    sortChatRooms() {
      this.chatData.sort((a, b) => b.timestamp - a.timestamp);
    },
    convertToDateTime(timestamp) {
      var dateTime = dayjs.unix(timestamp)
      return dateTime;
    },
    chatRoomTime(timestamp) {
      // var dateTime = new Date(parseInt(timestamp) * 1000);
      var dateTime = this.convertToDateTime(timestamp);
      return dayjs().to(dayjs(dateTime));
    },
    chatConvertationTime(timestamp) {
      // var dateTime = new Date(parseInt(timestamp) * 1000);
      let dateTime = dayjs(parseInt(timestamp) * 1000);
      return dayjs().calendar(dateTime, {
        sameDay: '[Today at]',
        nextDay: '[Yesterday at]',
        nextWeek: 'dddd',
        lastDay: '[Tomorrow at]',
        lastWeek: '[Last] dddd',
        sameElse: '['+dayjs(parseInt(timestamp) * 1000).format("DD-MM-YYYY")+' at]',
      })+" "+dayjs(parseInt(timestamp) * 1000).format("HH:mm");
    },
    async storeChat(msg) {
      const res = msg;
      const chatFrom = res.from.split("@");

      // const dataCustomer = {
      //   "name": res._data.notifyName,
      //   "phone": chatFrom[0]
      // }

      const formData = new FormData();
      formData.append("nama", res._data.notifyName);
      formData.append("phone", chatFrom[0]);

      // for debugging allow save number
      const validation = [
        62895326339070, 6285655401980, 628977219772, 628996752029,
      ];

      const isValid = validation.some((el) => el === parseInt(chatFrom[0]));

      if (isValid) {
        // store chat in db
        await axios
          .post(
            process.env.VUE_APP_BASE_URL + "api-helper/storeUserChat",
            formData
          )
          .then((response) => {
            if (response.data.Code == 200) {
              this.getUser("all");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async assignAgent(agent_id, customerID) {
      let extaParam = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt")
      let url = process.env.VUE_APP_URL_API + "customer-api/changeStatus" + extaParam

      let formData = new FormData();
      formData.append("Status", 1);
      formData.append("CustomerID", customerID);
      formData.append("AgentID", agent_id);

      const changeStatus = await axios.post(url, formData)
      let data = changeStatus.data

      if (data.Meta.Code !== 200) {
        this.Toast.fire({
          icon: 'error',
          title: data.Meta.Message
        });
        return;
      }

      this.Toast.fire({
        icon: 'success',
        title: data.Meta.Message
      });

      this.userStatus = "assigned";
      let idxNowChatData = this.chatData.findIndex(el => el.id === customerID);
      this.chatData[idxNowChatData]['status'] = this.userStatus;
      await this.refreshContact();
    },
    async openConvertation() {
      var kodemb = JSON.parse(localStorage.getItem("user")).Company.CompanyCode;

      const convertation = await axios.post(
        process.env.VUE_APP_WA_BASE_URL +
        "api/whatsapp/conversation?clientid=abcd1234",
        {
          phone: this.$route.params.id,
          limit: 25,
          fetched: this.chatMessagesData.length,
          kodemb,
        }
      );

      if (convertation.data.status == 200) {
        this.chatMessagesData = [];
        convertation.data.data.forEach((item) => {
          const regexItalic = /(_)([^_]+?)(_)/gs;
          const regexBold = /(\*)([^*]+?)(\*)/gs;

          var body = item.body;
          body = body.replace(regexItalic, `<i>$2</i>`);
          body = body.replace(regexBold, `<b>$2</b>`);

          this.chatMessagesData.push({
            align: item.fromMe === true ? "right" : "",
            name: this.username,
            message: body,
            time: item.timestamp,
            ack: item.ack,
            media: item.downloadedMedia
              ? item.downloadedMedia.data
              : item._data.body,
            fromMe: item.fromMe,
            phone: this.phoneNumber,
          });
        });

        var currentChatId = "users-chat";
        this.scrollToBottom(currentChatId);
      }
    },
    imageSend() {
      this.showFormUpload = true;
    },
    closeFormUpload() {
      this.showFormUpload = false;
      this.files = []
      this.dropzoneFile = ""
      this.imagesPreview = ""
    },
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
      this.files = []
      this.dropzoneFile = ""
      this.imagesPreview = ""
    },
    showMessageNotif(data) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      const resChat = JSON.parse(data);
      Toast.fire({
        title: resChat._data.notifyName.toUpperCase(),
        text: resChat.body,
      });
    },
    async resolveCustomer(status) {
      var indexCust = this.chatData.findIndex(
        (cust) => parseInt(cust.telp) === parseInt(this.phoneNumber)
      );

      var headers = new Headers();
      headers.append("ClientID", process.env.VUE_APP_CLIENT_ID);
      headers.append("AccessToken", localStorage.getItem("jwt"));

      var bodyParam = new FormData();
      bodyParam.append("Status", status);
      bodyParam.append("CustomerID", this.chatData[indexCust].id);
      if (status == 1) {
        bodyParam.append("AgentID", 0);
      } else {
        bodyParam.append("AgentID", parseInt(localStorage.getItem("userid")));
      }

      var query = `?ClientID=${process.env.VUE_APP_CLIENT_ID
        }&AccessToken=${localStorage.getItem("jwt")}`;
      const setStatus = await axios.post(
        process.env.VUE_APP_URL_API + "customer-api/changeStatus" + query,
        bodyParam
      );
      const res = setStatus.data;
      const chatDataNow = this.chatData[indexCust];
      if (res.Meta.Code === 200) {
        if (status === 3) {
          chatDataNow['status'] = 'resolved';
          this.userStatus = 'resolved';
          document
            .getElementById("chatInput")
            .setAttribute("disabled", "disabled");
          document
            .getElementById("chatInput")
            .setAttribute("placeholder", "Customer Is Resolved");
          document
            .getElementById("btnSendMessage")
            .setAttribute("disabled", "disabled");
        } else {
          chatDataNow['status'] = 'assigned';
          this.userStatus = 'assigned';
          document.getElementById("btnCanvasClose").click();
          document.getElementById("chatInput").removeAttribute("disabled");
          document.getElementById("btnSendMessage").removeAttribute("disabled");
          document.getElementById("chatInput").setAttribute("placeholder", "Enter Message...")
        }
      }

      await this.refreshContact();
    },
    async saveEdit() {
      let tagSave = [];
      if (this.tagValue) {
        this.tagValue.map((item) => {
          var checkTagOption = this.tagoption.findIndex(
            (idx) => idx.value === item
          );
          if (checkTagOption !== -1)
            tagSave.push({
              ID: item,
              Title: this.tagoption[checkTagOption].label,
            });
          else
            tagSave.push({
              ID: 0,
              Title: item,
            });
        });
      }

      const dataEdit = new FormData();
      dataEdit.append("ID", this.userid);
      dataEdit.append("Note", this.note);
      dataEdit.append("Tags", JSON.stringify(tagSave));

      var ClientID = process.env.VUE_APP_CLIENT_ID;
      var AccessToken = localStorage.getItem("jwt");

      const saveEdited = await axios
        .post(
          process.env.VUE_APP_URL_API +
          "customer-api/editCustomer?ClientID=" +
          ClientID +
          "&AccessToken=" +
          AccessToken,
          dataEdit
        )
        .then((response) => {
          return response;
        })
        .catch((er) => {
          return er;
        });

      var responseStatusCode = saveEdited.data.Meta.Code;
      var responseStatusMsg = saveEdited.data.Meta.Message;

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      if (responseStatusCode != 200) {
        Toast.fire({
          icon: "error",
          title: responseStatusMsg,
        });
      } else {
        var index = this.chatData.findIndex(
          (item) => item.telp === this.phoneNumber
        );
        this.chatData[index].Tags = tagSave
          .map((el) => {
            return el.Title;
          })
          .join(",");
        Toast.fire({
          icon: "success",
          title: responseStatusMsg,
        });
      }
    },
    async downloadMedia(idx_chat) {
      const messageData = this.chatMessagesData[idx_chat];
      const postData = {
        phone: messageData.phone,
        fetched: ((this.chatMessagesData.length - idx_chat) + 2),
        kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
        message_id: messageData.chatID,
        show_log: false
      }

      const downloadMedia = await axios.post(process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/download-media", postData, {
        headers: {
          clientid: 'abcd1234'
        }
      })

      if (downloadMedia.status === 200) {
        let data = downloadMedia.data;
        if (data.status !== 200) {
          this.Toast.fire({
            title: data.message,
            icon: 'warning'
          })
        }

        let imageDownloaded = data.data;
        if (("media" in imageDownloaded)) {
          if(imageDownloaded.media){
            messageData['media'] = "data:" + imageDownloaded.media.mimetype + ";base64," + imageDownloaded.media.data
          }
          messageData['downloadImage'] = false
          messageData['style'] = { filter: 'blur(0px)' }
          this.chatMessagesData[idx_chat] = messageData;
        } else {
          this.Toast.fire({
            title: data.message,
            icon: 'warning'
          })
        }
      } else {
        this.Toast.fire({
          title: downloadMedia.statusText,
          icon: 'error'
        })
      }
    },
    async unassign(status, agent_id) {
      let customerID = this.userid;

      let extaParam = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt")
      let url = process.env.VUE_APP_URL_API + "customer-api/changeStatus" + extaParam

      let formData = new FormData();
      formData.append("Status", status);
      formData.append("CustomerID", customerID);
      formData.append("AgentID", agent_id);

      const changeStatus = await axios.post(url, formData)
      let data = changeStatus.data

      if (data.Meta.Code !== 200) {
        this.Toast.fire({
          icon: 'error',
          title: data.Meta.Message
        });
        return;
      }

      this.Toast.fire({
        icon: 'success',
        title: data.Meta.Message
      });

      let indexAgentremove = this.agentHandle.findIndex(el => el.ID === agent_id);
      if (indexAgentremove !== -1) {
        this.agentHandle.splice(indexAgentremove, 1);
      }

      let urlCheck = `${process.env.VUE_APP_URL_API}customer-api/getCustomerByID`
      const checkAgent = await axios.get(urlCheck, {
        params: {
          ID: customerID,
          ClientID: process.env.VUE_APP_CLIENT_ID,
          AccessToken: localStorage.getItem("jwt")
        }
      })

      let setStatusCust = false;
      if (checkAgent.data.Data != null) {
        setStatusCust = checkAgent.data.Data.AgentAssigned === null;
      }

      let chatDataIndex = this.chatData.findIndex(el => el.id === this.userid);
      if (setStatusCust) {
        this.chatData[chatDataIndex]['status'] = 'unassigned'
      }
      this.refreshContact();
    },
    loadMoreContact(el) {
      var self = this;
      el.addEventListener("scroll", () => {
        let scrolPosisi = Math.round((el.scrollTop + 1) + el.getBoundingClientRect().height);
        if (scrolPosisi >= el.scrollHeight) {
          let start = self.chatData.length
          let to = self.itemsInPages + self.chatData.length
          
          setTimeout(async () => {
            let statusActive = document.querySelector("ul.tabStatus > li.tabLi > a.active")
            let statusTabs = statusActive ? statusActive.getAttribute("data-status") : 'all'
            
            let length = 0;
            let contact = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/countCustomer`,{
              params: {
                ClientID: process.env.VUE_APP_CLIENT_ID,
                AccessToken: localStorage.getItem("jwt"),
                Kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
                Status: statusTabs
              }
            });
            
            if(contact.status == 200){
              length = contact.data.Data;
            }

            if (start < length) {
              this.fetchChatRoom = true;
              await self.getUser(statusTabs, start, to, true);
            }
          }, 600);
          this.fetchChatRoom = false;
        }
      })
    },
    async refreshChatRoom() {
      localStorage.removeItem("ChatRooms")
      this.fetchChatRoom = true;
      await this.refreshContact();

      this.chatData = [];
      await this.getUser("All");
      this.Toast.fire({
        title: 'Success Load Chat Room',
        icon: 'success'
      })
    },
    async refreshContact(phone = 0) {
      console.log("refresh contact chat.vue");
      let userChat;
      let dataSave = [];
      let oldContact = await this.getAllContact();

      if(!oldContact.length){
        phone = 0;
      }

      if(phone != 0){
        console.log("Old Contact : ", oldContact);
        
        userChat = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/getCustomerByPhone`, {
          params: {
            ClientID: process.env.VUE_APP_CLIENT_ID,
            AccessToken: localStorage.getItem("jwt"),
            Phone: phone
          }
        })

        let responseData = null;
        if(userChat.status == 200){
          let response = userChat.data;
          if(response.Meta.Code == 200){
            responseData = response.Data;
          }
        }

        if(responseData !== null){
          oldContact.push(responseData)
        }
        dataSave = oldContact;
      } else {
        userChat = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/getCustomerData`, {
          params: {
            ClientID: process.env.VUE_APP_CLIENT_ID,
            AccessToken: localStorage.getItem("jwt"),
            Kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
            status: 'all',
            Start: 0,
            Count: 10
          }
        });

        if (userChat.data.Data !== null) {
          let sortContact = userChat.data.Data.sort((a, b) => this.toTimeStamp(b.TimestampLastChat) - this.toTimeStamp(a.TimestampLastChat))
          userChat.data.Data = sortContact
          dataSave = userChat.data.Data;
        }
      }

      await this.cleanDataDB();
      await this.addCustomerToDB(dataSave);
      await this.getUser("All");
    },
    searchChat(textSearch) {
      var searchKeyword = textSearch.toLocaleLowerCase();

      if (searchKeyword !== '' && searchKeyword.length >= 2) {
        let dataSearch = this.chatMessagesData.filter((data) => {
          return data.message.includes(searchKeyword);
        });

        this.resultSearchMessage = dataSearch;

        /* for (let _idx = 0; _idx < dataSearch.length; _idx++) {
          const item = dataSearch[_idx];
          let idxChat = this.chatMessagesData.findIndex(el => el.chatID === item.chatID);
          let message = this.chatMessagesData[idxChat]['message'].toLowerCase().trim();
          let startIdxMsg = message.indexOf(searchKeyword);
          let subMessage = message.substr(startIdxMsg);
          let splitMessage = subMessage.split(" ")[0];
          let lengthSearch = splitMessage.length;
          let msg = subMessage.substr(0, lengthSearch);
          let messageMark = `<mark>${msg}</mark>`;
          let re = new RegExp(msg, "g");
          this.chatMessagesData[idxChat]['message'] = message.replace(re, messageMark);
        } */
      }
    },
    debounce(func, timeout = 300) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(func, timeout);
    },
    goToMessageSearch(data) {
      let idxChatClick = this.chatMessagesData.findIndex(el => {
        return data.chatID === el.chatID;
      })
      this.chatMessagesData[idxChatClick]['showHightlight'] = true;

      let elementChat = document.querySelector(`#buble-${idxChatClick}`)
      elementChat.scrollIntoView();
    },
    async checkWhatsappStatus()
    {
      let kodemb = JSON.parse(localStorage.getItem("user")).Company.CompanyCode
      let urlStateWa = process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/status?kodemb=" + kodemb
      const statusWa = await axios.get(urlStateWa, {
        headers: {
          clientid: "abcd1234"
        }
      })

      let responseData = statusWa.data
      if (responseData.data === 'WAITING') {
        this.whatsappStatus = false;
      } else {
        this.whatsappStatus = true;
      }
    },
    closeConvertation()
    {
      this.chatMessagesData = [];
      let element = this.$el.querySelector(`#row-${this.userid}`)
      element.classList.remove("active");
    }
  },
  watch: {
    searchChatInput(value) {
      this.chatMessagesData.forEach((item, idx) => {
        // this.chatMessagesData[idx]['message'] = item.message.replace(/(<([^>]+)>)/ig, "");
        this.chatMessagesData[idx]['showHightlight'] = false;
      })

      this.debounce(() => {
        this.resultSearchMessage = [];
        this.searchChat(value)
      }, 1000);
    }
  },
  computed: {
    searchAgent() {
      if (this.searchAgentInput) {
        var keyword = document
          .getElementById("searchAgent")
          .value.toLowerCase();
        return this.agentData.filter((data) => {
          return data.Name.toLowerCase().includes(keyword);
        });
      } else {
        return this.agentData.slice(0, this.agentData.length);
      }
    },
    contactCount() {
      let numberOfPages = Math.ceil(this.chatData.length / this.page);
      return numberOfPages
    },
    contactListpaginate() {
      /* let page = this.page;
      let perPage = this.itemsInPages;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return this.chatData.slice(from, to); */
      return this.chatData;
    },
  },
  async beforeMount() {
    await this.checkWhatsappStatus();
    this.user = JSON.parse(localStorage.getItem("user"));

    axios
      .get(
        process.env.VUE_APP_URL_API +
        "agent-api/getAgentList?All=true&IsActive=on&ClientID=" +
        process.env.VUE_APP_CLIENT_ID +
        "&AccessToken=" +
        localStorage.getItem("jwt")
      )
      .then((response) => {
        if (response.data.Data !== null) {
          this.agentData = response.data.Data;
          if (!(this.agentData instanceof Array)) {
            this.agentData = [this.agentData];
          }
        }

        /* this.agentData.forEach((element, index) => {
          if (!element.Avatar) {
            element.Avatar = "https://i.pravatar.cc/160?img=29";
          } else {
            element.Avatar = element.Avatar.Original;
          }
          this.agentData[index] = element;
        }); */
      });

    if(this.whatsappStatus){
      this.Toast.fire({
        icon: "info",
        title: "WhatsApp connected, you mode Online"
      })
    } else {
      Swal.fire({
        title: 'WhatsApp is Offline',
        text: "Continue access offline ?",
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Yes',
      })
    }
  },
  async mounted() {
    console.log("masuk mounted chat component");
    await this.getUser("All");

    var id = "contactChatMessageList"
    var contactList = document.getElementById(id).querySelector("#contactSimpleBar .simplebar-content-wrapper")
      ? document.getElementById(id).querySelector("#contactSimpleBar .simplebar-content-wrapper")
      : "";
    this.loadMoreContact(contactList)

    if (this.$route.params.id) {
      setTimeout(() => {
        // this.openConvertation();
        let idx_ = this.chatData.findIndex((el) => {
          return el.telp === parseInt(this.$route.params.id);
        });

        if (idx_ !== -1) {
          let users = this.chatData[idx_];
          this.chatUsername(users.name, users.image, users.telp, users.id);
        } else {
          console.log("data tidak ada");
          this.Toast.fire({
            icon: "warning",
            title: `Contact ${this.$route.params.id} tidak tersedia`
          })
        }
      }, 4000);
    }

    if (this.chatMessagesData.length != 0) {
      var currentChatId = "users-chat";
      this.scrollToBottom(currentChatId);
      document.getElementById("copyClipBoard").style.display = "none";
      var userChatElement = document.querySelectorAll(".user-chat");

      document
        .querySelectorAll(".chat-user-list li a")
        .forEach(function (item) {
          item.addEventListener("click", function () {
            userChatElement.forEach(function (elm) {
              elm.classList.add("user-chat-show");
            });

            // chat user list link active
            var chatUserList = document.querySelector(
              ".chat-user-list li.active"
            );
            if (chatUserList) chatUserList.classList.remove("active");
            this.parentNode.classList.add("active");
          });
        });

      // user-chat-remove
      document.querySelectorAll(".user-chat-remove").forEach(function (item) {
        item.addEventListener("click", function () {
          userChatElement.forEach(function (elm) {
            elm.classList.remove("user-chat-show");
          });
        });
      });
    }

    /* setInterval(() => {
      console.log("Setup on 2 minutes refresh contact");
      document.querySelector("#btnRefresh").click();
    }, 120000); */
  },
  async created() {
    console.log("masuk created component chat");
    var self = this;

    this.initSocket();

    this.socket.on("customer_unassigned", async function (data) {
      console.log("socket unassigned");
      let customerData = JSON.parse(data)
      let dataContactIDB = await self.getAllContact();      
      let idxChatData = dataContactIDB.findIndex(el => {
        return parseInt(el.Phone) === parseInt(customerData.Phone)
      })
      if(idxChatData !== -1){
        dataContactIDB.splice(idxChatData, 1);
        /* let savedContactLocal = {
          data: {
            Data: self.chatData
          }
        }
        localStorage.setItem("Contact", JSON.stringify(savedContactLocal)); */
        await self.cleanDataDB();
        await self.addCustomerToDB(dataContactIDB)
        await self.getUser("All");
      } else {
        await self.refreshContact();
      }
    });

    this.socket.on("chat", async function (data) {
      console.log("chat masuk");
      var res = JSON.parse(data);
      var chatFrom = "";
      if (res.fromMe) {
        chatFrom = res.to.split("@")[0];
      } else {
        chatFrom = res.from.split("@")[0];
      }
      await self.pushToTop(parseInt(chatFrom), res);
      self.showMessageNotif(data);

      var phone;
      if (res.fromMe) {
        phone = res.to.split("@")[0];
      } else {
        phone = res.from.split("@")[0];
      }

      var idxChatdata = self.chatData.findIndex(
        (e) => parseInt(e.telp) === parseInt(phone)
      );

      const regexItalic = /(_)([^_]+?)(_)/gs;
      const regexBold = /(\*)([^*]+?)(\*)/gs;
      const regexStrike = /(~)([^~]+?)(~)/gs;

      var body = res.body;
      body = body.replace(regexItalic, `<i>$2</i>`);
      body = body.replace(regexBold, `<b>$2</b>`);
      body = body.replace(regexStrike, `<strike>$2</strike>`);

      if(idxChatdata !== -1){
        self.chatData[idxChatdata]["type"] = res.type;
        self.chatData[idxChatdata]["hasMedia"] = res.hasMedia;
        self.chatData[idxChatdata]["message"] = body;
        self.chatData[idxChatdata]["timestamp"] = res.timestamp;
        self.chatData[idxChatdata]["fromMe"] = res.fromMe;
      }

      let checkObjectStore = self.db.objectStoreNames.contains("customer");
      if (checkObjectStore) {
        let contactLocal = JSON.stringify({
          data: {
            Data: await self.getAllContact()
          }
        });
        let contactUpdate = JSON.parse(contactLocal).data.Data.find(el => parseInt(el.Phone) === parseInt(phone));
        if (!contactUpdate) {
          await self.refreshContact(parseInt(chatFrom));
        }
      }

      if (localStorage.getItem("ChatRooms") !== null) {
        localStorage.removeItem("ChatRooms");
        localStorage.setItem("ChatRooms", JSON.stringify(self.chatData));
      }

      let idxChatRoom = idxChatdata !== -1 ? idxChatdata : 0;
      let contactID = self.chatData[idxChatRoom].id;
      let checkRoomActive = document.querySelector(`#row-${contactID}`)

      let classActive = false;
      if(self.$route.path == "/chat"){
        classActive = checkRoomActive.classList.contains("active")
      }

      if ((parseInt(self.phoneNumber) === parseInt(phone)) && classActive) {
        self.convertationActive = true;
        axios.post(
          process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/mark-as-read",
          {
            phone: chatFrom,
            kodemb: JSON.parse(localStorage.getItem("user")).Company
              .CompanyCode,
          },
          {
            headers: {
              clientid: "abcd1234",
            },
          }
        );

        body = body.replace(/\n/g, "<br/>");

        // for auto push to convertation data
        self.chatMessagesData.push({
          align: res.fromMe === true ? "right" : "",
          name: self.username,
          chatID: res.id.id,
          message: body,
          time: res.timestamp,
          hasMedia: res.hasMedia,
          ack: res.ack,
          fromMe: res.fromMe,
          phone: self.phoneNumber,
          type: res.type,
        });

        var index = self.chatMessagesData.length - 1;

        if (res.hasQuotedMsg && "quotedMsg" in res._data) {
          if ("deprecatedMms3Url" in res._data.quotedMsg) {
            res._data.quotedMsg.body =
              "data:" +
              res._data.quotedMsg.mimetype +
              ";base64," +
              res._data.quotedMsg.body;
          }
          var splitParticipant = res._data.quotedParticipant.split("@");
          self.chatMessagesData[index]["quotedMsg"] = res._data.quotedMsg;
          self.chatMessagesData[index]["quotedParticipant"] = {
            user: splitParticipant[0],
            server: splitParticipant[1],
            _serialized: res._data.quotedParticipant,
            name:
              parseInt(splitParticipant[0]) === self.phoneNumber
                ? self.username
                : "Anda",
          };
        }

        if ("deprecatedMms3Url" in res._data) {
          self.chatMessagesData[index]["media"] =
            "data:" + res._data.mimetype + ";base64," + res._data.body;
        }

        var currentChatId = "users-chat";
        self.scrollToBottom(currentChatId);
      }
    });

    this.socket.on("chat_status_changed", (data) => {
      var res = JSON.parse(data);
      console.log("Status change");
      if (res.fromMe) {
        var toChat = res.to.split("@")[0];
        var index = self.chatData.findIndex((el) => el.telp === parseInt(toChat));
        if(index !== -1){
          self.chatData[index].ack = res.ack;
        }

        let idxChatRoom = index !== -1 ? index : 0;
        let contactID = self.chatData[idxChatRoom].id;
        let checkRoomActive = document.querySelector(`#row-${contactID}`)
        let classActive = false;
        if(checkRoomActive){
          classActive = checkRoomActive.classList.contains("active")
        }
        if ((parseInt(self.phoneNumber) === parseInt(toChat)) && classActive) {
          var idx = self.chatMessagesData.findIndex(
            (item) => item.chatID === res.id.id
          );
          self.chatMessagesData[idx].ack = res.ack;
        }
      }

      self.cachingChatData();
    });

    this.socket.on("chat_create", async (data) => {
      var res = JSON.parse(data);
      self.cachingChatData();

      if (res.fromMe) {
        console.log("chat create");
        let agentName = "";
        let agentCode = "";

        if (Object.keys(res.agent)) {
          if ("Name" in res.agent) {
            agentName = res.agent.Name;
          }

          if ("Role" in res.agent) {
            agentCode = res.agent.Role.Code;
          }
        }

        if("agent" in res){
          if(!("Name" in res.agent)){
            res.agent = {
              ID: 0,
              Name: "System",
              Role: {
                Title: "Bot"
              }
            }
          }
        } else {
          res.agent = {
            ID: 0,
            Name: "System",
            Role: {
              Title: "Bot"
            }
          }
        }

        self.agentChatName = agentName;
        self.agentChatRole = agentCode;

        var chatFrom = res.to.split("@")[0];
        self.pushToTop(parseInt(chatFrom), res, self);

        let chatRoomActive = self.chatData.findIndex(el => {
          return parseInt(el.telp) === parseInt(chatFrom)
        })

        let idxChatRoom = chatRoomActive !== -1 ? chatRoomActive : 0;
        let contactID = self.chatData[idxChatRoom].id;
        let checkRoomActive = document.querySelector(`#row-${contactID}`)
        let classActive = checkRoomActive.classList.contains("active")

        if ((parseInt(self.phoneNumber) == parseInt(chatFrom)) && classActive) {
          console.log("masuk convertation active");
          self.convertationActive = true;
          axios.post(
            process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/mark-as-read",
            {
              phone: chatFrom,
              kodemb: JSON.parse(localStorage.getItem("user")).Company
                .CompanyCode,
            },
            {
              headers: {
                clientid: "abcd1234",
              },
            }
          );

          const regexItalic = /(_)([^_]+?)(_)/gs;
          const regexBold = /(\*)([^*]+?)(\*)/gs;
          const regexStrike = /(~)([^~]+?)(~)/gs;

          var body = res.body;
          body = body.replace(regexItalic, `<i>$2</i>`);
          body = body.replace(regexBold, `<b>$2</b>`);
          body = body.replace(regexStrike, `<strike>$2</strike>`);
          body = body.replace(/\n/g, "<br/>");

          // for auto push to convertation data
          self.chatMessagesData.push({
            align: res.fromMe === true ? "right" : "",
            name: self.username,
            chatID: res.id.id,
            message: body,
            time: res.timestamp,
            hasMedia: res.hasMedia,
            ack: res.ack == 0 ? 1 : res.ack,
            fromMe: res.fromMe,
            phone: self.phoneNumber,
            type: res.type,
          });

          let chatIndex;
          chatIndex = self.chatMessagesData.findIndex(
            (chat) => chat.chatID === res.id.id
          );

          if (chatIndex === -1) {
            chatIndex = self.chatMessagesData.length - 1;
          }

          self.chatMessagesData[chatIndex]["agent"] = null;
          if ("agent" in res) {
            if ("Name" in res.agent) {
              self.chatMessagesData[chatIndex]["agent"] = res.agent;
            }
          }

          if ("deprecatedMms3Url" in res._data) {
            if ("downloadedMedia" in res) {
              this.chatMessagesData[chatIndex]["media"] = res.downloadedMedia
                ? "data:" +
                res.downloadedMedia.mimetype +
                ";base64," +
                res.downloadedMedia.data
                : "data:" +
                res.downloadedMedia.mimetype +
                ";base64," +
                res._data.body;
              this.styleObject[chatIndex] = { filter: "blur(0px)" };
              this.downloadImage[chatIndex] = false;
            } else {
              if (res.type === "image" || res.type === "chat") {
                this.styleObject[chatIndex] = { filter: "blur(3px)" };
                this.downloadImage[chatIndex] = true;
                var mimeType = "";
                if ("mimetype" in res._data) {
                  mimeType = res._data.mimetype;
                } else {
                  mimeType = "image/png";
                }
                res._data.type = "image";
                this.chatMessagesData[chatIndex]["media"] = "data:" + mimeType + ";base64," + res._data.body;
                this.chatMessagesData[chatIndex]["style"] = {
                  filter: 'blur(3px)'
                };
                this.chatMessagesData[chatIndex]["downloadImage"] = true;
              }
            }

            switch (res._data.type) {
              case "sticker":
                self.chatMessagesData[chatIndex]["type"] = "sticker";
                break;

              case "image":
                self.chatMessagesData[chatIndex]["type"] = "image";
                var caption_body = res._data.caption;
                caption_body = caption_body?.replace(regexItalic, `<i>$2</i>`);
                caption_body = caption_body?.replace(regexBold, `<b>$2</b>`);
                caption_body = caption_body?.replace(
                  regexStrike,
                  `<strike>$2</strike>`
                );
                self.chatMessagesData[chatIndex]["message"] = res._data.caption
                  ? caption_body
                  : "";
                break;

              case "video":
                self.chatMessagesData[chatIndex]["type"] = "video";
                self.chatMessagesData[chatIndex]["message"] =
                  "<i>Unsupported Message Content</i>";
                break;

              default:
                break;
            }
          }

          if (res.hasQuotedMsg && "quotedMsg" in res._data) {
            if ("deprecatedMms3Url" in res._data.quotedMsg) {
              res._data.quotedMsg.body =
                "data:" +
                res._data.quotedMsg.mimetype +
                ";base64," +
                res._data.quotedMsg.body;
            }
            var splitParticipant = res._data.quotedParticipant.split("@");
            self.chatMessagesData[chatIndex]["quotedMsg"] = res._data.quotedMsg;
            self.chatMessagesData[chatIndex]["quotedParticipant"] = {
              user: splitParticipant[0],
              server: splitParticipant[1],
              _serialized: res._data.quotedParticipant,
              name:
                parseInt(splitParticipant[0]) === self.phoneNumber
                  ? self.username
                  : "Anda",
            };
          }

          var currentChatId = "users-chat";
          self.scrollToBottom(currentChatId);
        }

        if (localStorage.getItem("ChatRooms") !== null) {
          localStorage.removeItem("ChatRooms");
          localStorage.setItem("ChatRooms", JSON.stringify(self.chatData));
        }
      }
      
      let phone = 0;
      if(!res.fromMe){
        phone = parseInt(res.from.split("@")[0])
      } else {
        phone = parseInt(res.to.split("@")[0])
      }

      let idxChatData = self.chatData.findIndex(el => {
        return parseInt(el.telp) === phone
      })

      let contact = await self.getAllContact();
      // let contactItem = JSON.parse(localStorage.getItem("Contact")).data.Data.find(el => {
      let contactItem = contact.find(el => {
        return parseInt(el.Phone) === phone
      })

      if(contactItem){
        this.chatData[idxChatData]['status'] = contactItem.Status;
      }
    });
  },
};
</script>

<style>
@import "~vue-awesome-notifications/dist/styles/style.css";
</style>

<template>
  <Layout>
    <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
      <div class="chat-leftsidebar" id="contactChatMessageList">
        <div class="pt-4">
          <div class="px-4">
            <div class="d-flex align-items-start">
              <div class="flex-grow-1">
                <h5 class="mb-4">Inbox</h5>
              </div>
              <div class="flex-shrink-0" v-if="this.fetchChatRoom">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </div>
              <div v-else class="flex-shrink-0">
                <button type="button" id="btnRefresh"
                  class="btn btn-outline-primary btn-sm btn-icon waves-effect waves-light shadow-none"
                  @click="refreshChatRoom"><i class="ri-refresh-line"></i></button>
              </div>
            </div>
          </div>
          <!-- Nav tabs -->
          <ul class="tabStatus nav nav-tabs nav-tabs-custom nav-success nav-justified" role="tablist"
            style="font-size: 9px;">
            <li class="nav-item tabLi">
              <a class="nav-link active" data-bs-toggle="tab" href="#all" data-status="all" @click="getUser('all')"
                role="tab">
                All
              </a>
            </li>
            <li class="nav-item tabLi">
              <a class="nav-link" data-bs-toggle="tab" href="#all" data-status="unassigned" @click="getUser('unassigned')"
                role="tab">
                Unassigned
              </a>
            </li>
            <li class="nav-item tabLi">
              <a class="nav-link" data-bs-toggle="tab" href="#all" data-status="assigned" @click="getUser('assigned')"
                role="tab">
                Assigned
              </a>
            </li>
            <li class="nav-item tabLi">
              <a class="nav-link" data-bs-toggle="tab" href="#all" data-status="resolved" @click="getUser('resolved')"
                role="tab">
                Resolved
              </a>
            </li>
          </ul>
        </div>
        <!-- .p-4 -->

        <div class="chat-room-list" id="contactSimpleBar" ref="contactSimpleBar" style="max-height: calc(100vh - 235px)"
          data-simplebar>
          <div class="col-xxl-12">
            <!-- Tab panes -->
            <div class="tab-content text-muted">
              <div class="tab-pane active" id="all" role="tabpanel">
                <div v-if="this.chatData.length === 0 && !this.shimmerOn" class="chat-message-list mt-5">
                  <img :src="this.userNotFound" />
                  <p class="text-center mt-2">No User Found</p>
                </div>

                <div class="chat-message-list">
                  <SimpleBar ref="contactList" v-if="this.chatData.length !== 0"
                    class="list-unstyled chat-list chat-user-list">
                    <li ref="listUser" v-for="data of contactListpaginate" :key="data.id" :class="[{ active: this.username == data.name }]" :id="`row-${data.id}`">
                      <a href=" " @click="chatUsername(data.name, data.image, data.telp, data.id)">
                        <div class="d-flex align-items-center w-100">
                          <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                            <div class="avatar-xs" v-if="data.image">
                              <img :src="`${data.image}`" :id="`${'image-' + data.telp}`"
                                class="rounded-circle img-fluid userprofile" alt />
                            </div>
                            <div class="avatar-xs" v-if="!data.image">
                              <div class="avatar-title rounded-circle bg-danger userprofile">
                                {{ data.name.charAt(0) }}
                              </div>
                            </div>
                          </div>

                          <div class="flex-grow-1 overflow-hidden">
                            <p :id="`${'name-' + data.telp}`" class="text-truncate m-0 mt-1">
                              {{ data.name }}
                            </p>
                            <!-- last messages text -->
                            <div v-if="data.shimmer" class="flex-grow-1">
                              <p class="col-8 m-0" style="font-size: 10px;">waiting...</p>
                            </div>
                            <div v-else>
                              <p :class="[
                                'text-truncate',
                                'text-weight',
                                { 'fw-bold': data.unread_count },
                              ]" style="font-size: 10px; margin: 0" v-if="!data.hasMedia" data-bs-toggle="tooltip"
                                data-bs-html="true" :title="`${data.message}`">
                                <i v-show="data.fromMe" :class="[
                                  {
                                    'ri-error-warning-line':
                                      data.ack === 0 && data.fromMe,
                                  },
                                  {
                                    'ri-error-warning-line text-danger':
                                      data.ack === 4 && data.fromMe,
                                  },
                                  {
                                    'ri-check-double-line text-success':
                                      data.ack === 3 && data.fromMe,
                                  },
                                  {
                                    'ri-check-line':
                                      data.ack === 1 && data.fromMe,
                                  },
                                  {
                                    'ri-check-double-line':
                                      data.ack === 2 && data.fromMe,
                                  },
                                ]"></i>
                                <span v-if="data.fromMe">&nbsp;</span>
                                <span v-html="data.message"></span>
                              </p>
                              <p :class="[
                                'text-truncate',
                                'text-weight',
                                { 'fw-bold': data.unread_count },
                              ]" style="font-size: 10px; margin: 0" v-if="data.hasMedia">
                                <i v-show="data.fromMe" :class="[
                                  {
                                    'ri-error-warning-line':
                                      data.ack === 0 && data.fromMe,
                                  },
                                  {
                                    'ri-error-warning-line text-danger':
                                      data.ack === 4 && data.fromMe,
                                  },
                                  {
                                    'ri-check-double-line text-success':
                                      data.ack === 3 && data.fromMe,
                                  },
                                  {
                                    'ri-check-line':
                                      data.ack === 1 && data.fromMe,
                                  },
                                  {
                                    'ri-check-double-line':
                                      data.ack === 2 && data.fromMe,
                                  },
                                ]"></i>

                                <i :class="[
                                  { 'ri-image-line': data.type === 'image' },
                                  { 'ri-live-line': data.type === 'video' },
                                  {
                                    'ri-sticky-note-fill':
                                      data.type === 'sticker',
                                  },
                                ]" v-if="data.hasMedia"></i>&nbsp;
                                <span v-if="data.hasMedia && data.type !== 'revoked'">
                                  {{ data.type }}
                                </span>
                                <span v-show="data.type === 'revoked'"><i>pesan ini dihapus</i></span>
                              </p>
                            </div>
                            <!-- end last message -->
                            <p v-if="data.Tags" class="text-truncate text-weight" style="font-size: 10px; width: 100px">
                              <i class="ri-price-tag-3-line"></i>
                              {{ data.Tags }}
                            </p>
                            <p v-else class="text-truncate text-weight" style="font-size: 10px; width: 100px">
                              <i class="ri-price-tag-3-line"></i>
                              No Tags
                            </p>
                          </div>

                          <div class="flex-grow-1 float-end ms-2" style="margin-top: -15px">
                            <div class="" v-if="data.shimmer">
                              <small class="col-8 m-0 time float-end text-end"
                                style="height:15px;margin-top: 8px; font-size: 10px;">00:00</small>
                            </div>
                            <small v-else class="time float-end" style="font-size: 10px">{{ chatRoomTime(data.timestamp)
                            }}</small>
                            <br />
                            <span v-show="data.unread_count" class="badge rounded-pill badge-soft-danger float-end"
                              style="font-size: 10px">{{ data.unread_count }}</span><br />
                            <span class="badge rounded-pill float-end" :class="[
                              { 'badge-soft-primary': data.status === 'assigned' },
                              { 'badge-soft-warning': data.status === 'unassigned' },
                              { 'badge-soft-success': data.status === 'resolved' },
                              { 'badge-soft-info': data.status === 'served by bot' },
                            ]" style="font-size: 10px">{{ data.status }}</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </SimpleBar>
                </div>
              </div>
            </div>
          </div>
          <!-- End chat-message-list -->
        </div>
      </div>
      <!-- end chat leftsidebar -->
      <!-- Start User chat -->
      <div v-if="chatMessagesData.length != 0" id="conv-1" class="user-chat w-100 overflow-hidden">
        <div class="chat-content d-lg-flex">
          <!-- start chat conversation section -->
          <div class="w-100 overflow-hidden position-relative">
            <!-- conversation user -->
            <div class="position-relative">
              <div class="p-3 user-chat-topbar">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-8">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 d-block d-lg-none me-3">
                        <a @click="closeConvertation" href="" class="user-chat-remove fs-18 p-1"><i
                            class="ri-arrow-left-s-line align-bottom"></i></a>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                            <img :src="profile
                                ? profile
                                : require('@/assets/images/users/user-dummy-img.jpg')
                              " class="rounded-circle avatar-xs" alt="" />
                            <!-- <span class="user-status"></span> -->
                          </div>
                          <div class="flex-grow-1 overflow-hidden">
                            <h5 class="text-truncate mb-0 fs-16">
                              <a class="text-reset username" data-bs-toggle="offcanvas" href="#userProfileCanvasExample"
                                aria-controls="userProfileCanvasExample">{{ username }}</a>
                            </h5>
                            <p class="text-truncate text-muted fs-14 mb-0 userStatus">
                              <small> {{ phoneNumber }} </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-8 col-4">
                    <ul class="list-inline user-chat-nav text-end mb-0">
                      <li class="list-inline-item m-0 border-end pe-3" v-if="user.Role.Code === 'principal' &&
                        userStatus !== 'resolved'
                        ">
                        <div class="dropdown">
                          Assigned to:
                          <button class="btn btn-outline-success shadow-none btn-sm" type="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Assign +
                          </button>
                          <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                            <div class="p-2">
                              <div class="search-box">
                                <input type="text" class="form-control bg-light border-light" placeholder="Search here..."
                                  id="searchAgent" v-model="searchAgentInput" />
                                <i class="ri-search-2-line search-icon"></i>
                              </div>
                            </div>
                            <div style="max-height: 250px; overflow: scroll; overflow-x: hidden;">
                              <div v-for="(data, index) of searchAgent" class="p-2" :key="index">
                                <div class="card mb-1 ribbon-box ribbon-fill ribbon-sm">
                                  <!-- <div class="ribbon ribbon-info"><i class="ri-flashlight-fill"></i></div> -->
                                  <div class="card-body">
                                    <a class="d-flex align-items-center" @click="assignAgent(data.ID, userid)">
                                      <div class="flex-shrink-0">
                                        <img :src="`${data.Avatar == null ? this.profile : data.Avatar.Original}`" alt=""
                                          class="avatar-xs rounded-circle shadow" />
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 class="fs-14 mb-1">
                                          {{ data.Name }}
                                        </h6>
                                        <p class="text-muted mb-0">
                                          {{ data.Role.Title }}
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                <!--end card-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="list-inline-item m-0 ms-1" v-if="userStatus == 'assigned'">
                        <!-- Rounded Buttons -->
                        <button type="button" class="btn btn-info btn-label btn-sm waves-effect waves-light rounded-pill"
                          @click="resolveCustomer(3)">
                          <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>
                          Resolve
                        </button>
                      </li>

                      <li class="list-inline-item m-0 ms-1" onclick="document.querySelector('#searchMessage').focus()">
                        <div class="dropdown">
                          <button class="btn btn-ghost-secondary btn-icon shadow-none" type="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <SearchIcon class="icon-sm"></SearchIcon>
                          </button>
                          <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                            <div class="p-2">
                              <div class="search-box">
                                <input v-model="searchChatInput" type="text" class="form-control bg-light border-light"
                                  placeholder="Search here..." id="searchMessage" />
                                <i class="ri-search-2-line search-icon"></i>
                              </div>
                              <div v-if="resultSearchMessage.length"
                                style="max-height: 250px; overflow: scroll; overflow-x: hidden;">
                                <div class="p-2">
                                  <div class="card mb-2" v-for="data in resultSearchMessage" :key="data.chatID">
                                    <!-- <div class="ribbon ribbon-info"><i class="ri-flashlight-fill"></i></div> -->
                                    <div class="card-body" @click="goToMessageSearch(data)">
                                      <a class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                          <h6 class="fs-14 mb-1">
                                            {{ this.chatConvertationTime(parseInt(data.time)) }}
                                          </h6>
                                          <p class="text-muted mb-0">
                                            <i class="ri-check-double-fill" style="margin-top: 10px;"
                                              v-if="data.align == 'right'"></i>
                                            {{ data.message }}
                                          </p>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <!--end card-->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="list-inline-item d-none d-lg-inline-block m-0">
                        <button type="button" class="btn btn-ghost-secondary btn-icon shadow-none"
                          data-bs-toggle="offcanvas" data-bs-target="#userProfileCanvasExample"
                          aria-controls="userProfileCanvasExample" @click="getUserDetil">
                          <!-- <info-icon class="icon-sm"></info-icon> -->
                          <i class="ri-information-line" style="font-size: 20px"></i>
                        </button>
                      </li>

                      <li class="list-inline-item d-none d-lg-inline-block m-0">
                        <button type="button" class="btn btn-ghost-secondary btn-icon shadow-none" @click="closeConvertation">
                          <!-- <info-icon class="icon-sm"></info-icon> -->
                          <i class="ri-close-line" style="font-size: 20px"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end chat user head -->
              <div class="position-relative" id="users-chat">
                <div v-if="this.showFormUpload">
                  <DropZone @drop.prevent="drop" @change="selectedFile" />
                  <ul class="list-unstyled mb-0" id="dropzone-preview">
                    <div class="border rounded" v-for="(file, index) of files" :key="index">
                      <div class="d-flex p-2">
                        <div class="flex-shrink-0 me-3">
                          <div class="avatar-sm bg-light rounded">
                            <img data-dz-thumbnail="" class="img-fluid rounded d-block" :src="imagesPreview"
                              alt="ttdku.png" />
                          </div>
                        </div>
                        <div class="flex-grow-1">
                          <div class="pt-1">
                            <h5 class="fs-14 mb-1" data-dz-name="">
                              {{ file.name }}
                            </h5>
                            <p class="fs-13 text-muted mb-0" data-dz-size="">
                              <strong>{{ file.size / 1024 }}</strong> KB
                            </p>
                            <strong class="error text-danger" data-dz-errormessage=""></strong>
                          </div>
                        </div>
                        <div class="flex-shrink-0 ms-3">
                          <button data-dz-remove="" class="btn btn-sm btn-danger" @click="deleteRecord">
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </ul>
                  <button class="btn btn-sm btn-danger w-100 m-2" @click="closeFormUpload">
                    Close
                  </button>
                </div>

                <div class="chat-conversation p-3 p-lg-4" id="chat-conversation" v-if="!this.showFormUpload"
                  data-simplebar ref="current">
                  <ul class="list-unstyled chat-conversation-list h-auto">
                    <li v-for="(data, index) of chatMessagesData" :key="data.message" :class="{
                      right: `${data.align}` === 'right',
                      left: `${data.align}` !== 'right',
                      'bg-soft-warning': data.showHightlight
                    }" class="chat-list" :id="'buble-' + index">
                      <div class="conversation-list">
                        <div class="chat-avatar" v-if="data.align !== 'right'">
                          <img :src="profile
                              ? profile
                              : require('@/assets/images/users/user-dummy-img.jpg')
                            " alt="" />
                        </div>
                        <div class="user-chat-content">
                          <div class="ctext-wrap" v-show="data.hasMedia">
                            <div class="message-img-link ml-4" v-if="data.align === 'right'">
                              <ul class="list-inline mb-0">
                                <li class="list-inline-item dropdown" v-if="data.type !== 'revoked'">
                                  <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-fill"></i>
                                  </a>
                                  <div class="dropdown-menu" style="">
                                    <a class="dropdown-item reply-message" href="" @click="
                                      replyMessages(
                                        data.message,
                                        data.align === 'right'
                                          ? 'You'
                                          : username,
                                        data.chatID,
                                        data.hasMedia,
                                        data
                                      )
                                      ">
                                      <i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply
                                    </a>
                                    <a class="dropdown-item" href="#">
                                      <i class="ri-share-line me-2 text-muted align-bottom"></i>Forward
                                    </a>
                                    <a v-if="data.ack === 4" class="dropdown-item" href="#"
                                      @click="resendMessage(data)"><i
                                        class="ri-send-plane-fill me-2 text-muted align-bottom"></i>Resend</a>
                                    <a class="dropdown-item" href="" v-if="data.downloadImage"
                                      @click="downloadMedia(index)">
                                      <i class="ri-download-cloud-2-line me-2 text-muted align-bottom"></i>Download
                                    </a>
                                  </div>
                                </li>
                              </ul>
                              <div class="conversation-name">
                                <small class="text-muted time">{{
                                  chatConvertationTime(data.time)
                                }}</small>

                                <small class="text-muted time" v-if="'agent' in data">
                                  by. {{ data.agent != null ? data.agent.Name : "" }} • {{ data.agent != null ?
                                    data.agent.Role.Title : "" }}
                                </small>

                                <span class="check-message-icon">
                                  <i class="align-bottom" :class="[
                                    {
                                      'ri-error-warning-line text-danger':
                                        data.ack === 4 && data.fromMe,
                                    },
                                    {
                                      'ri-time-line':
                                        data.ack === 0 && data.fromMe,
                                    },
                                    {
                                      'ri-check-double-line text-success':
                                        data.ack === 3 && data.fromMe,
                                    },
                                    {
                                      'ri-check-line':
                                        data.ack === 1 && data.fromMe,
                                    },
                                    {
                                      'ri-check-double-line':
                                        data.ack === 2 && data.fromMe,
                                    },
                                  ]"></i>
                                </span>
                              </div>
                            </div>
                            <div class="message-img mb-0">
                              <div class="message-img-list">
                                <div>
                                  <a data-fslightbox="gallery" class="popup-img d-inline-block" href=""
                                    @click="setDataModal(data)">
                                    <!-- {{data.type}} -->
                                    <div v-if="data.type === 'image'">
                                      <img :id="`chat-images-${index}`" :src="data.media" alt="" class="rounded border ms-3" :style="data.style"
                                        style="min-width: 250px" />
                                      <!-- <FsLightbox
                                        :toggler="toggler"
                                        :sources="[
                                          'https://i.imgur.com/fsyrScY.jpg',
                                          'https://www.youtube.com/watch?v=3nQNiWdeH2Q',
                                          'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                                        ]"
                                      /> -->
                                    </div>
                                    <div v-if="data.type === 'sticker'">
                                      <img :src="data.media" alt="" class="rounded border" style="min-width: 100px" />
                                    </div>
                                  </a>
                                  <div v-if="data.type === 'video'">
                                    <!-- <video width="auto" controls>
                                      <source :src="data.media" type="video/mp4">
                                    </video> -->

                                    <!-- <div class="ratio ratio-16x9" style="min-width: 200px;">
                                        <iframe :src="data.media" title="YouTube video" allowfullscreen></iframe>
                                    </div> -->
                                  </div>
                                  <div class="ctext-wrap-content" v-if="data.message" style="text-align: left">
                                    <div v-html="data.message" class="text-break" style="max-width: 300px"></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Convertation left start -->
                            <div class="message-img-link" v-if="data.align !== 'right'">
                              <ul class="list-inline mb-0">
                                <li class="list-inline-item dropdown" v-if="data.type !== 'revoked'">
                                  <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-fill"></i>
                                  </a>
                                  <div class="dropdown-menu" style="">
                                    <a class="dropdown-item reply-message" href="" @click="
                                      replyMessages(
                                        data.message,
                                        data.align === 'right'
                                          ? 'You'
                                          : username,
                                        data.chatID,
                                        data.hasMedia,
                                        data
                                      )
                                      ">
                                      <i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply
                                    </a>
                                    <a class="dropdown-item" href="#">
                                      <i class="ri-share-line me-2 text-muted align-bottom"></i>Forward
                                    </a>
                                    <a v-if="data.ack === 4" class="dropdown-item" href="#"
                                      @click="resendMessage(data)"><i
                                        class="ri-send-plane-fill me-2 text-muted align-bottom"></i>Resend</a>
                                    <a class="dropdown-item" href="" v-if="data.downloadImage"
                                      @click="downloadMedia(index)">
                                      <i class="ri-download-cloud-2-line me-2 text-muted align-bottom"></i>Download
                                    </a>
                                  </div>
                                </li>
                              </ul>
                              <div class="conversation-name">
                                <small class="text-muted time">{{
                                  chatConvertationTime(data.time)
                                }}</small>

                                <small class="text-muted time" v-if="'agent' in data">by. {{ data.agent.Name }}•{{
                                  data.agent.Role.Title
                                }}</small>

                                <span class="check-message-icon">
                                  <i class="align-bottom" :class="[
                                        {
                                          'ri-error-warning-line text-danger':
                                            data.ack === 4 && data.fromMe,
                                        },
                                        {
                                          'ri-time-line':
                                            data.ack === 0 && data.fromMe,
                                        },
                                        {
                                          'ri-check-double-line text-success':
                                            data.ack === 3 && data.fromMe,
                                        },
                                        {
                                          'ri-check-line':
                                            data.ack === 1 && data.fromMe,
                                        },
                                        {
                                          'ri-check-double-line':
                                            data.ack === 2 && data.fromMe,
                                        },
                                      ]"></i>
                                </span>
                              </div>
                            </div>
                            <!-- Convertation right end -->
                          </div>
                          <div class="ctext-wrap" v-if="data.message && !data.hasMedia">
                            <div class="ctext-wrap-content">
                              <div v-if="data.quotedMsg" class="replymessage-block mb-0 d-flex align-items-start">
                                <div class="flex-grow-1">
                                  <h5 class="" :class="{
                                    'text-primary': data.align === 'right',
                                  }">
                                    {{ data.quotedParticipant.name }}
                                  </h5>
                                  <div v-if="'deprecatedMms3Url' in data.quotedMsg &&
                                    data.quotedMsg.type !== 'sticker'
                                    ">
                                    <i class="ri-camera-fill"></i>
                                    Photo
                                  </div>
                                  <div v-if="data.quotedMsg.type === 'sticker'">
                                    Sticker
                                  </div>
                                  <div v-if="data.quotedMsg.type === 'chat' &&
                                    !('deprecatedMms3Url' in data.quotedMsg)
                                    ">
                                    <p class="mb-0">
                                      {{ data.quotedMsg.body }}
                                    </p>
                                  </div>
                                </div>
                                <img v-if="'deprecatedMms3Url' in data.quotedMsg" :src="data.quotedMsg.body" alt=""
                                  style="height: 50px; width: auto; margin-left: 20px;" />
                              </div>
                              <div v-html="data.message" :class="{ 'mt-2': data.quotedMsg }" class="text-break"
                                style="max-width: 300px; text-align: left"></div>
                            </div>
                            <div class="dropdown align-self-start message-box-drop" v-if="data.type !== 'revoked'">
                              <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="ri-more-2-fill"></i>
                              </a>
                              <div class="dropdown-menu">
                                <a class="dropdown-item reply-message" href="" @click="
                                  replyMessages(
                                    data.message,
                                    data.align === 'right' ? 'You' : username,
                                    data.chatID,
                                    data.hasMedia,
                                    data
                                  )
                                  "><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                <!-- <a class="dropdown-item" href="#"
                                  ><i
                                    class="ri-share-line me-2 text-muted align-bottom"
                                  ></i
                                  >Forward</a
                                >
                                <a class="dropdown-item copy-message" href="#"
                                  ><i
                                    class="ri-file-copy-line me-2 text-muted align-bottom"
                                  ></i
                                  >Copy</a
                                > -->
                                <a v-if="data.ack === 4" class="dropdown-item" href="#" @click="resendMessage(data)"><i
                                    class="ri-send-plane-fill me-2 text-muted align-bottom"></i>Resend</a>
                                <!-- <a class="dropdown-item delete-item" href="#"
                                  ><i
                                    class="ri-delete-bin-5-line me-2 text-muted align-bottom"
                                  ></i
                                  >Delete</a
                                > -->
                              </div>
                            </div>
                            <div class="conversation-name">
                              <small class="text-muted time">{{
                                chatConvertationTime(data.time)
                              }}</small>

                              <small class="text-muted time" v-if="'agent' in data">by. {{ data.agent.Name }}•{{
                                data.agent.Role.Title
                              }}</small>

                              <span class="check-message-icon">
                                <i class="align-bottom" :class="[
                                      {
                                        'ri-error-warning-line text-danger':
                                          data.ack === 4 && data.fromMe,
                                      },
                                      {
                                        'ri-time-line':
                                          data.ack === 0 && data.fromMe,
                                      },
                                      {
                                        'ri-check-double-line text-success':
                                          data.ack === 3 && data.fromMe,
                                      },
                                      {
                                        'ri-check-line':
                                          data.ack === 1 && data.fromMe,
                                      },
                                      {
                                        'ri-check-double-line':
                                          data.ack === 2 && data.fromMe,
                                      },
                                    ]"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <!-- end chat-conversation-list -->
                </div>

                <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show" id="copyClipBoard"
                  role="alert">
                  Message copied
                </div>
              </div>

              <!-- end chat-conversation -->

              <div class="chat-input-section p-3 p-lg-4">
                <form @submit.prevent="formSubmit">
                  <div class="row g-0 align-items-center">
                    <div class="col-auto">
                      <div class="chat-input-links me-2">
                        <div class="links-list-item">
                          <button type="button" class="btn btn-link text-decoration-none emoji-btn" id="emoji-btn"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bx-smile align-middle"></i>
                          </button>
                          <div class="dropdown-menu" style="background: none; height: auto">
                            <EmojiPicker :native="true" :display-recent="false" @select="selectEmoji" />
                          </div>

                          <button type="button" class="btn btn-link text-decoration-none emoji-btn"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bx-paperclip align-middle"></i>
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="" @click="imageSend">Images or Video</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="chat-input-feedback">
                        Please Enter a Message
                      </div>
                      <input type="text" v-model="form.message" class="form-control chat-input bg-light border-light"
                        placeholder="Enter Message..." id="chatInput" />
                    </div>
                    <div class="col-auto">
                      <div class="chat-input-links ms-2">
                        <div class="links-list-item">
                          <button id="btnSendMessage" type="submit"
                            class="btn btn-xmarks2 chat-send waves-effect waves-light shadow">
                            <i class="ri-send-plane-2-fill align-bottom"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <!-- messages reply -->
              <div class="replyCard" :class="{ show: this.messagesReply }">
                <div class="card mb-0">
                  <div class="card-body py-3">
                    <div class="replymessage-block mb-0 d-flex align-items-start">
                      <div class="flex-grow-1">
                        <h5 class="conversation-name">{{ nameReply }}</h5>
                        <p class="mb-0" v-if="!isMediaReply">
                          {{ pesanReply }}
                        </p>
                        <p class="text-truncate text-weight" style="font-size: 10px; margin: 0">
                          <img v-if="isSticker" :src="previewImages" alt=""
                            style="height: 40px; width: auto; margin-right: 10px;" />
                        </p>
                        <p class="text-truncate text-weight" style="font-size: 10px; margin: 0"
                          v-if="isMediaReply && !isSticker">
                          <i class="ri-image-line"></i>
                          Photo
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <img v-if="isMediaReply && !isSticker" :src="previewImages" alt=""
                          style="height: 40px; width: auto; margin-right: 10px" />
                        <button type="button" id="close_toggle" @click="closeReply" class="btn btn-sm mt-n2 me-n3">
                          <i class="bx bx-x align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="chatMessagesData.length == 0" id="conv-0" class="user-chat w-100 overflow-hidden" style="margin: auto">
        <div class="chat-content d-lg-flex">
          <div class="w-100" style="padding: 20px; margin: auto">
            <div class="align-items-center">
              <!-- <img src="https://www.xaviermarks.com/assets/Uploads/logo.png" style="width: 100%;" class="img-fluid justify-content-center" /> -->
              <img src="../../assets/images/widget-img.png" style="width: 80%" class="img-fluid justify-content-center" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end chat-wrapper -->

    <!-- Modal for open images -->
    <div class="modal fade zoomIn" tabindex="-1" aria-labelledby="zoomInModalLabel" style="display: none"
      aria-hidden="true" id="zoomInModal">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content text-center" style="width: auto">
          <div class="modal-header">
            <h5 class="modal-title" id="zoomInModalLabel">
              {{ captionModal }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img class="img-fluid align-item-center" :src="this.imageModal" style="min-width: 250px; margin: auto" />
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
    </div>
    <!-- end modals -->

    <!-- Lightbox images -->
    <FsLightbox 
      :toggler="toggler"
      type="image"
      :sources="[sourcesImages]"
    />
    <!-- End Lightbox -->

    <!-- canvas -->
    <div class="offcanvas offcanvas-end border-0" tabindex="-1" id="userProfileCanvasExample">
      <!--end offcanvas-header-->
      <div class="offcanvas-body profile-offcanvas p-0">
        <div class="team-cover">
          <img src="@/assets/images/small/img-9.jpg" alt="" class="img-fluid" />
        </div>
        <div class="p-1 pb-4 pt-0">
          <div class="team-settings">
            <div class="row g-0">
              <div class="col">
                <div class="btn nav-btn">
                  <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"
                    id="btnCanvasClose"></button>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <div class="p-3 text-center">
          <img :src="profile
              ? profile
              : require('@/assets/images/users/user-dummy-img.jpg')
            " alt="" class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img" />
          <div class="mt-3">
            <h5 class="fs-16 mb-1">
              <a href="javascript:void(0);" class="link-primary username">{{
                username
              }}</a>
            </h5>
            <p class="text-muted">
              <i class="ri-checkbox-blank-circle-fill me-1 align-bottom" :class="[
                    { 'text-info': this.userStatus === 'assigned' },
                    { 'text-danger': this.userStatus === 'unassigned' },
                    { 'text-success': this.userStatus === 'resolved' },
                  ]"></i>{{ this.userStatus }}
            </p>
          </div>
        </div>

        <div class="border-top border-top-dashed p-3">
          <h5 class="fs-15 mb-3">Personal Details</h5>
          <div class="mb-3">
            <p class="text-muted text-uppercase fw-medium fs-12 mb-1">Number</p>
            <h6>{{ phoneNumber }}</h6>
          </div>
          <div class="row">
            <div class="mb-3 col-6">
              <p class="text-muted text-uppercase fw-medium fs-12 mb-1">
                Create
              </p>
              <h6>{{ create }}</h6>
            </div>
            <div class="col-6">
              <p class="text-muted text-uppercase fw-medium fs-12 mb-1">
                LastEdit
              </p>
              <h6 class="mb-0">{{ lastEdit }}</h6>
            </div>
          </div>
        </div>

        <div class="border-top border-top-dashed p-3">
          <h5 class="fs-15 mb-3">Utils</h5>
          <label>note</label>
          <textarea v-model="note" class="form-control mb-3" id="notes" rows="3" placeholder="note"
            style="height: 111px"></textarea>
          <label>tag</label>
          <Multiselect v-model="this.tagValue" mode="tags" :close-on-select="false" :searchable="true"
            :create-option="true" :options="tagoption" />
        </div>

        <div class="border-top border-top-dashed p-3">
          <h5 class="fs-15 mb-3">Agent Assigned</h5>
          <div class="vstack gap-2">
            <div class="border rounded border-dashed p-2" v-for="(agent, index) in agentHandle" :key="index">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <div class="avatar-title bg-light text-secondary rounded fs-20 shadow">
                      <img :src="agent.avatar" alt="" class="avatar-sm rounded-circle" />
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="fs-13 mb-1">
                    <a href="#" class="text-body text-truncate d-block">{{
                      agent.Name
                    }}</a>
                  </h5>
                  <div class="text-muted">Agent</div>
                </div>
                <div class="flex-shrink-0 ms-2" v-if="user.Role.Code === 'principal'">
                  <div class="d-flex gap-1">
                    <button type="button" class="btn btn-icon text-danger btn-sm fs-18 shadow-none"
                      @click="unassign(2, agent.ID)">
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center mt-2">
              <div class="row">
                <div :class="[
                  { 'col-6': userStatus === 'resolved' },
                  { 'col-12': userStatus !== 'resolved' }
                ]">
                  <button @click="saveEdit" type="button" class="btn btn-info col-12">
                    Saved <i class="ri-save-3-fill align-bottom"></i>
                  </button>
                </div>
                <div class="col-6" v-if="userStatus === 'resolved'">
                  <button type="button" class="btn btn-warning col-12" @click="resolveCustomer(1)">
                    Unresolved <i class="ri-close-line align-bottom"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end offcanvas-body-->
    </div>
    <!-- end canvas -->
  </Layout>
</template>
